<template>
  <v-dialog
    v-if="contactperson && $currentUser"
    v-model="show"
    width="600"
    persistent
    hide-overlay
    @keydown.esc="closeDialog"
  >
    <v-card>
      <v-card-title class="headline primary white--text"
        >{{ contactperson.contactperson_id ? 'Rediger kontaktperson' : 'Opprett ny kontaktperson'
        }}<v-spacer></v-spacer>
        <v-btn v-if="!loading" icon color="white" @click="closeDialog">
          <v-icon>close</v-icon>
        </v-btn>
        <v-progress-circular v-else indeterminate color="white" size="36"></v-progress-circular>
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-text-field
            v-model="contactperson.first_name"
            label="Fornavn"
            clearable
            :rules="[$validationRules.required]"
            :disabled="loading"
            @blur="handleFirstNameField"
            @keyup.enter="handleFirstNameField"
          ></v-text-field>
          <v-text-field
            v-model="contactperson.last_name"
            label="Etternavn"
            clearable
            :rules="[$validationRules.required]"
            :disabled="loading"
            @blur="handleLastNameField"
            @keyup.enter="handleLastNameField"
          ></v-text-field>
          <v-combobox
            v-model="contactperson.phonenumbers"
            label="Telefon"
            item-text="phonenumber"
            multiple
            small-chips
            deletable-chips
            return-object
            :disabled="!contactperson.contactperson_id || loading"
          ></v-combobox>
          <v-combobox
            v-model="contactperson.emailaddresses"
            label="E-post"
            item-text="emailaddress"
            multiple
            small-chips
            deletable-chips
            return-object
            :disabled="!contactperson.contactperson_id || loading"
            @input="handleEmailaddressInput"
          />
          <v-autocomplete
            ref="companies"
            v-model="contactperson.companies"
            :items="companies"
            label="Bedrifter"
            multiple
            small-chips
            deletable-chips
            no-data-text="Fant ingen bedrifter som matcher søket"
            item-text="name"
            return-object
            :search-input.sync="search.companies"
            cache-items
            :disabled="!contactperson.contactperson_id || loading"
            @change="handleCompanySelected"
          >
            <template v-slot:item="company">{{ company.item.name }}</template>

            <template v-slot:append-item>
              <v-btn
                color="primary"
                text
                small
                class="mt-2 ml-2"
                :disabled="search.loading"
                @click="
                  showCompanyDialog({
                    fields: {
                      contactpersons: [contactperson],
                      user_id: $currentUser.user_id,
                    },
                  })
                "
              >
                <v-icon>add</v-icon>Opprett ny bedrift
              </v-btn>
              <v-progress-circular v-if="search.loading" indeterminate size="24" class="ml-2">
              </v-progress-circular>
            </template>
          </v-autocomplete>
        </v-form>
        <ErrorAlert v-bind="errorAlert" />
      </v-card-text>
    </v-card>

    <CompanyDialog
      :show="companyDialog.show"
      :company="companyDialog.company"
      @closeAndReset="closeAndResetCompanyDialog"
      @setNewEntity="setNewCompany"
    />
  </v-dialog>
</template>

<script>
import debounce from 'debounce'
import companyDialog from '@/mixins/companyDialog'
import currentUser from '@/mixins/currentUser'
import entityPersistence from '@/mixins/entityPersistence.js'
import errorAlert from '@/mixins/errorAlert.js'
import validationRules from '@/mixins/validationRules'
import Contactperson from '@/models/Contactperson'
import Phonenumber from '@/models/Phonenumber'
import ContactpersonPhonenumber from '@/models/ContactpersonPhonenumber'
import Emailaddress from '@/models/Emailaddress'
import ContactpersonEmailaddress from '@/models/ContactpersonEmailaddress'
import Company from '@/models/Company'
import CompanyContactperson from '@/models/CompanyContactperson'
import ErrorAlert from './ErrorAlert'

export default {
  name: 'ContactpersonDialog',
  components: {
    ErrorAlert,
  },
  mixins: [companyDialog, currentUser, entityPersistence, errorAlert, validationRules],
  props: {
    show: Boolean,
    contactperson: {
      type: Contactperson,
      default: null,
    },
  },
  data: () => {
    return {
      loading: false,
      errorAlert: {
        show: false,
        errors: [],
      },
      search: {
        companies: null,
        loading: false,
      },
      companies: [],
      rules: {
        required: value => !!value || 'Påkrevd',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Ugyldig epostadresse'
        },
      },
    }
  },

  computed: {
    entity() {
      return this.contactperson
    },
  },

  watch: {
    show() {
      if (this.show && this.contactperson) {
        this.companies = this.contactperson.companies
      }
    },

    'contactperson.phonenumbers'(newEntities, oldEntities) {
      this.clearErrorAlert()
      this.createAddedSimpleRelatedEntities(
        newEntities,
        oldEntities,
        Phonenumber,
        ContactpersonPhonenumber
      )
      this.removeRelatedEntities(newEntities, oldEntities, true)
    },

    'contactperson.emailaddresses'(newEntities, oldEntities) {
      this.clearErrorAlert()
      this.createAddedSimpleRelatedEntities(
        newEntities,
        oldEntities,
        Emailaddress,
        ContactpersonEmailaddress
      )
      this.removeRelatedEntities(newEntities, oldEntities, true)
    },

    'contactperson.companies'(newEntities, oldEntities) {
      this.clearErrorAlert()
      this.createAddedAdvancedRelatedEntities(
        newEntities,
        oldEntities,
        Company,
        CompanyContactperson
      )
      this.removeRelatedEntities(newEntities, oldEntities)
      return
    },

    'search.companies': {
      handler: debounce(async function(searchFor) {
        this.companies = this.contactperson.companies
        this.clearErrorAlert()
        if (!searchFor || searchFor.length < 3) {
          return
        }

        const searchResult = await this.fetchCompanies(searchFor)
        if (searchResult) {
          this.companies = [...this.contactperson.companies, ...searchResult]
        }
      }, 250),
    },
  },

  beforeCreate: function() {
    this.$options.components.CompanyDialog = require('@/components/CompanyDialog').default
  },

  methods: {
    async fetchCompanies(searchFor) {
      if (!searchFor) {
        return
      }

      let result
      try {
        this.search.loading = true
        result = await Company.fetchAll({ search: { name: searchFor } })
      } catch (e) {
        this.handleCompanySelected(null)
        this.handleError([e.message, 'Kunne ikke søke etter bedrifter'])
      } finally {
        this.search.loading = false
      }
      return result.entities.companies
    },

    async handleFirstNameField() {
      if (!this.show || !this.$refs.form.validate()) {
        return
      }

      if (this.contactperson.contactperson_id) {
        await this.createOrUpdateEntity(
          Contactperson,
          { first_name: this.contactperson.first_name },
          this.contactperson.contactperson_id
        )
      } else {
        await this.createOrUpdateEntity(Contactperson, {
          first_name: this.contactperson.first_name,
          last_name: this.contactperson.last_name,
        })
      }
    },

    async handleLastNameField() {
      if (!this.show || !this.$refs.form.validate()) {
        return
      }

      if (this.contactperson.contactperson_id) {
        await this.createOrUpdateEntity(
          Contactperson,
          { last_name: this.contactperson.last_name },
          this.contactperson.contactperson_id
        )
      } else {
        await this.createOrUpdateEntity(Contactperson, {
          first_name: this.contactperson.first_name,
          last_name: this.contactperson.last_name,
        })
      }
    },

    async handleCompanySelected() {
      this.$refs.companies.isMenuActive = false
    },

    handleEmailaddressInput(emailaddresses) {
      this.contactperson.emailaddresses = emailaddresses.map(item => {
        if (!item.emailaddress) {
          return { emailaddress: item }
        }
        return item
      })
    },

    async setNewCompany(company) {
      try {
        this.loading = true
        company.pivot = await CompanyContactperson.createOne({
          contactperson_id: this.contactperson.contactperson_id,
          company_id: company.company_id,
        })

        this.contactperson.companies.push(company)
        this.companyDialog.company = company
        this.companyDialog.company.contactpersons.push(this.contactperson)
        this.companyDialog.contactpersons.push(this.contactperson)
      } catch (e) {
        this.handleError([e.message, 'Kunne ikke legge til bedrift'])
      } finally {
        this.loading = false
      }
    },

    handleError(message) {
      this.showErrorAlert(message)
      this.loading = false
    },

    async closeDialog() {
      await this.fetchStoredContactpersonIfFormInvalid()
      this.clearErrorAlert()
      this.$emit('closeAndReset')
    },

    async fetchStoredContactpersonIfFormInvalid() {
      try {
        if (this.contactperson.contactperson_id && !this.$refs.form.validate()) {
          await Contactperson.fetchOne(this.contactperson.contactperson_id)
        }
      } catch {
        //do nothing
      }
    },
  },
}
</script>
