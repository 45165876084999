<template>
  <v-app>
    <MainNav />
    <v-main>
      <v-container>
        <router-view @toast="toast" />

        <v-snackbar v-model="snackbar.show" v-bind="snackbar.props">
          {{ snackbar.text }}
          <template v-slot:action="{ attrs }">
            <v-btn
              icon
              v-bind="attrs"
              :class="snackbar.props ? snackbar.props.contentClass : ''"
              @click="snackbar.show = false"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import MainNav from './components/MainNav'
import Acceptancetype from '@/models/Acceptancetype'
import Agreementtype from '@/models/Agreementtype'
import Our_contactinfo from '@/models/Our_contactinfo'
import Permission from '@/models/Permission'
import User from '@/models/User'
import UserPermission from '@/models/UserPermission'
import Industry from '@/models/Industry'

export default {
  name: 'App',
  components: {
    MainNav,
  },
  data: function() {
    return {
      snackbar: {
        show: false,
        props: this.defaultSnackbarProps,
      },
    }
  },
  computed: {
    defaultSnackbarProps() {
      return {
        color: 'primary',
        text: 'white',
        bottom: true,
        left: false,
        multiLine: false,
        right: false,
        timeout: -1,
        top: false,
        vertical: false,
        contentClass: '',
      }
    },
  },
  async beforeCreate() {
    try {
      await this.$auth.renewTokens()
    } catch (e) {
      console.log('Error renewing tokens', e)
    }

    try {
      await Promise.all([User.fetchAll(), Permission.fetchAll(), UserPermission.fetchAll()])
      Industry.fetchAll()
      Our_contactinfo.fetchAll()
      Acceptancetype.fetchAll()
      Agreementtype.fetchAll()
    } catch (e) {
      console.log('Error fetching data: ', e)
    }
  },
  methods: {
    toast(toast) {
      this.snackbar.text = toast.text
      this.snackbar.props = toast.props ? toast.props : this.defaultSnackbarProps
      this.snackbar.show = true
    },
  },
}
</script>

<style lang="less">
@grey-darken-2: #616161;
@grey-darken-3: #424242;
@grey-darken-4: #212121;

@primary: #303f9f;
@secondary: #3f51b5;
@accent: #ff5722;
@error: #b71c1c;

@primary-lighten-1: lighten(@primary, 25%);
@primary-lighten-2: lighten(@primary, 40%);

.grey--lighten-5 {
  background-color: #fafafa;
}
.grey--lighten-4 {
  background-color: #f5f5f5;
}
.grey--lighten-3 {
  background-color: #eeeeee;
}
.grey--lighten-2 {
  background-color: #e0e0e0;
}
.grey--lighten-1 {
  background-color: #bdbdbd;
}
.grey--darken-1 {
  background-color: #757575;
}
.grey--darken-2 {
  background-color: #616161;
}
.grey--darken-3 {
  background-color: #424242;
}
.grey--darken-4 {
  background-color: #212121;
}

.cursorPointer {
  cursor: pointer;
}

.white-space-nowrap {
  white-space: nowrap;
}

.v-text-field__details .v-messages__message {
  /* stop validation error messages from overflowing */
  line-height: 1.3;
}

pre {
  font-family: Roboto, sans-serif;
  white-space: pre-wrap;
}

.v-textarea textarea {
  line-height: 1.5;
}

.add-item-link {
  text-transform: uppercase;
  color: @primary;
  font-weight: 500;
  display: inline-block;
  vertical-align: middle;
  font-size: 1.1em;

  &:hover {
    color: @primary-lighten-1;
  }

  &:before {
    content: '+';
    margin-right: 0.2em;
    font-size: 1.3em;
  }
}

.has-soft-box-shadow {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
</style>
