import BaseModel from './BaseModel'
import Permission from './Permission'
import UserPermission from './UserPermission'
import Company from './Company'
import CompanyUser from './CompanyUser'

export default class User extends BaseModel {
  static entity = 'users'
  static primaryKey = 'user_id'

  static fields() {
    return {
      user_id: this.attr(null),
      first_name: this.string(),
      last_name: this.string(),
      phone: this.string(),
      email: this.string(),
      active: this.boolean(),
      permissions: this.belongsToMany(Permission, UserPermission, 'user_id', 'permission_id'),
      companies: this.belongsToMany(Company, CompanyUser, 'user_id', 'company_id'),
    }
  }

  get full_name() {
    return `${this.first_name} ${this.last_name}`
  }

  hasPermission(permission) {
    const found = this.permissions.find(element => element.name == permission)
    if (found) {
      return true
    }
  }
}
