import { VChip } from 'vuetify/lib/components/VChip';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VSelect,{attrs:{"items":_vm.colors,"label":_vm.label,"outlined":"","rules":_vm.rules,"disabled":_vm.disabled},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c(VChip,{staticStyle:{"width":"95%"},attrs:{"label":"","color":item}})]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c(VChip,{staticStyle:{"width":"95%"},attrs:{"label":"","color":item}})]}}]),model:{value:(_vm.selectedColor),callback:function ($$v) {_vm.selectedColor=$$v},expression:"selectedColor"}})}
var staticRenderFns = []

export { render, staticRenderFns }