import BaseModel from '@/models/BaseModel'

export default class companyContactperson extends BaseModel {
  static entity = 'companies_contactpersons'

  static primaryKey = ['company_id', 'contactperson_id']

  static fields() {
    return {
      company_id: this.attr(null),
      contactperson_id: this.attr(null),
      /*
      company: this.belongsTo(Company, 'company_id'),
      contactperson: this.belongsTo(Contactperson, 'contactperson_id'),
      */
    }
  }

  static methodConf = {
    methods: {
      $delete: {
        name: 'delete',
        http: {
          url: '/:company_id/:contactperson_id/',
          method: 'delete',
        },
      },
    },
  }
}
