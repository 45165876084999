<template>
  <v-dialog v-if="entity" value="show" persistent :max-width="maxWidth" @keydown.esc="closeDialog">
    <v-card>
      <v-card-title class="headline primary white--text">
        {{ entity.$id ? editTitle : createTitle }}
        <v-spacer></v-spacer>
        <v-btn v-if="!loading" icon color="white" @click="closeDialog">
          <v-icon>close</v-icon>
        </v-btn>
        <v-progress-circular v-else indeterminate color="white" size="36"></v-progress-circular>
      </v-card-title>
      <v-card-text>
        <slot name="default"></slot>
        <slot name="form"></slot>
        <ErrorAlert v-bind="errorAlert" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import entityPersistence from '@/mixins/entityPersistence.js'
import errorAlert from '@/mixins/errorAlert.js'
import responseHelper from '@/mixins/responseHelper'
import ErrorAlert from '@/components/ErrorAlert'

export default {
  name: 'BaseDialog',
  components: {
    ErrorAlert,
  },
  mixins: [entityPersistence, errorAlert, responseHelper],
  props: {
    show: Boolean,
    loading: Boolean,
    entity: {
      type: Object,
      default: null,
    },
    maxWidth: {
      type: Number,
      default: 400,
    },
    editTitle: {
      type: String,
      default: 'Rediger',
    },
    createTitle: {
      type: String,
      default: 'Opprett ny',
    },
  },

  methods: {
    closeDialog() {
      this.clearErrorAlert()
      this.$emit('closeAndReset')
    },
  },
}
</script>
