import BaseModel from './BaseModel'

export default class contactpersonPhonenumber extends BaseModel {
  static entity = 'contactpersons_phonenumbers'

  static primaryKey = ['contactperson_id', 'phonenumber_id']

  static fields() {
    return {
      contactperson_id: this.attr(null),
      phonenumber_id: this.attr(null),
    }
  }

  static methodConf = {
    methods: {
      $delete: {
        name: 'delete',
        http: {
          url: '/:contactperson_id/:phonenumber_id/',
          method: 'delete',
        },
      },
    },
  }
}
