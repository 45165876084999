export default {
  methods: {
    combineArraysWithoutDuplicates(arr1, arr2) {
      if (!arr1 && !arr2) {
        return []
      }
      if (!arr1) {
        return arr2
      }
      if (!arr2) {
        return arr1
      }

      return [...new Set([...arr1, ...arr2])]
    },
  },
}
