import Agreement from '@/models/Agreement'

export default {
  data: () => {
    return {
      agreementDialog: {
        show: false,
        agreement: null,
      },
    }
  },
  methods: {
    showAgreementDialog(
      params = {
        fields: {
          contract_id: this.contract.contract_id,
          discount_percentage: 0,
          duration_months: 12,
        },
      }
    ) {
      if (params.agreement) {
        this.agreementDialog.agreement = params.agreement
      } else {
        this.agreementDialog.agreement = new Agreement(params.fields)
      }
      this.agreementDialog.show = true
    },
    closeAgreementDialog() {
      this.agreementDialog.show = false
      this.agreementDialog.agreement = null
    },
  },
}
