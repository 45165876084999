import BaseModel from './BaseModel'
import Agreement from './Agreement'

export default class Agreementtype extends BaseModel {
  static entity = 'agreementtypes'
  static primaryKey = 'agreementtype_id'

  static fields() {
    return {
      agreementtype_id: this.attr(null),
      name: this.string(),
      agreements: this.hasMany(Agreement, 'agreementtype_id'),
    }
  }
}
