export default {
  methods: {
    getDiscountAmount(fullPrice, discountPercentage) {
      return fullPrice * (discountPercentage / 100)
    },
    getDiscountedPrice(fullPrice, discountPercentage) {
      return fullPrice * (1 - discountPercentage / 100)
    },
    getTotalAmount(agreements) {
      let totalAmount = 0
      agreements.forEach(agreement => {
        totalAmount += this.getDiscountedPrice(agreement.full_price, agreement.discount_percentage)
      })
      return totalAmount
    },
    getVAT(agreements) {
      let totalAmount = this.getTotalAmount(agreements)
      return totalAmount * 0.25
    },
    getTotalInclVAT(agreements) {
      let totalAmount = this.getTotalAmount(agreements)
      return totalAmount * 1.25
    },
  },
}
