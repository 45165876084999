<template>
  <v-select
    v-model="selectedColor"
    :items="colors"
    :label="label"
    outlined
    :rules="rules"
    :disabled="disabled"
  >
    <template v-slot:selection="{ item }">
      <v-chip label :color="item" style="width: 95%;"></v-chip>
    </template>
    <template v-slot:item="{ item }">
      <v-chip label :color="item" style="width: 95%;"></v-chip>
    </template>
  </v-select>
</template>

<script>
import colors from 'vuetify/lib/util/colors'

export default {
  name: 'ColorSelect',
  components: {},
  mixins: [],
  props: {
    value: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Farge',
    },
    rules: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  computed: {
    selectedColor: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    colors() {
      return Object.values(colors)
        .filter(color => color.base)
        .map(color => color.base.toUpperCase())
    },
  },
}
</script>
