import { Model } from '@vuex-orm/core'
import { getIdsFromEntities, getFirstReturnedInstance } from '@/mixins/responseHelper'

export default class BaseModel extends Model {
  get primaryKey() {
    let primaryKey = { isComposite: false, key: {} }

    const keys = this.constructor.primaryKey

    if (Array.isArray(keys)) {
      primaryKey.isComposite = true

      keys.forEach(key => {
        primaryKey.key[key] = this[key]
      })
    } else {
      primaryKey.key[keys] = this[keys]
    }

    return primaryKey
  }

  update(data) {
    return this.constructor.updateOne(this.$id, data)
  }

  static createOrUpdateOne(id, data = {}) {
    if (id) {
      return this.updateOne(id, data)
    } else {
      return this.createOne(data)
    }
  }

  static async createOne(data = {}) {
    if (typeof data != 'object') {
      const transformedData = {}
      transformedData[this.dataField] = data
      data = transformedData
    }

    const url = `${this.entity}`
    const result = await this.api().post(url, data)
    return result.entities[this.entity][0]
  }

  static fetchAll(params, config) {
    let searchQuery = ''
    if (params && params.search) {
      searchQuery = `?q=${JSON.stringify(params.search)}`
    }

    const url = `${this.entity}${searchQuery}`
    return this.api().get(url, config)
  }

  static async fetchOne(id, deleteAll = false, relationships) {
    if (!id) return

    if (deleteAll) {
      this.deleteAll()
    }

    const response = await this.api().get(`${this.entity}/${id}`)

    const instance = getFirstReturnedInstance(response)

    await this.fetchRelationships(instance, relationships)

    return response
  }

  static async fetchRelationships(instances, relationships = [], awaitLeafNodeFetches = false) {
    if (!instances || !relationships.length) return

    const relationshipTree = this.buildRelationshipTree(relationships)

    await this.fetchRelationshipNode(instances, relationshipTree, awaitLeafNodeFetches)
  }

  static async fetchRelationshipNode(instance, node, awaitLeafNodeFetches = false) {
    const fetchPromises = []

    for (const relationship in node) {
      if (!this.hasChildNodes(node[relationship])) {
        // If this is a leaf node we can fetch it's data data without waiting for response
        const leafNodeFetch = this.fetchRelationship(instance, relationship).catch(err => {
          console.error(`Error fetching relationship ${relationship}:`, err)
        })

        if (awaitLeafNodeFetches) {
          fetchPromises.push(leafNodeFetch)
        }
      } else {
        // If this is a branch node we need to fetch this node's data before recursively fetching the child data
        // We can run this branch's fetch and its sibling branches' fetches in parallel
        const branchFetch = async () => {
          const relatedInstances = await this.fetchRelationship(instance, relationship)
          await this.fetchRelationshipNode(
            relatedInstances,
            node[relationship],
            awaitLeafNodeFetches
          )
        }
        fetchPromises.push(branchFetch())
      }
    }

    await Promise.all(fetchPromises)
  }

  static hasChildNodes(node) {
    return Object.keys(node).length > 0
  }

  static getInstancesFromResponse(response) {
    return response?.entities[response.model.entity] ?? []
  }

  static isNestedRelationship(relationship) {
    return relationship.includes('.')
  }

  static getRelationshipParts(relationship) {
    return relationship.split('.')
  }

  static buildRelationshipTree(relationships) {
    const root = {}

    relationships.forEach(relationship => {
      let parts = relationship.split('.')
      let currentNode = root

      parts.forEach(part => {
        // If the part doesn't exist in the current node, add it
        if (!currentNode[part]) {
          currentNode[part] = {}
        }
        // Move to the next part of the relationship
        currentNode = currentNode[part]
      })
    })

    return root
  }

  static async fetchRelationship(instances, relationship) {
    if (!instances || this.isEmptyArray(instances)) return

    if (!Array.isArray(instances)) {
      instances = [instances]
    }

    const field = this.getFieldByInstanceAndName(instances.at(0), relationship)

    if (!field) throw new Error(`Unknown relationship field: ${relationship}`)

    const primaryKeyField = instances.at(0).constructor.primaryKey

    const instanceIds = this.getInstanceValuesByKey(instances, primaryKeyField)

    let result

    switch (this.getRelationshipType(field)) {
      case 'HasOne':
      case 'HasMany': {
        const relatedIds = this.getInstanceValuesByKey(instances, field.localKey)
        result = await field.related.fetchBatchByKey(field.localKey, relatedIds)
        break
      }
      case 'BelongsToMany': {
        const response = await field.pivot.fetchBatchByKey(primaryKeyField, instanceIds)
        const relatedIds = getIdsFromEntities(response, field.relatedKey)
        if (relatedIds) {
          result = await field.related.fetchBatch(relatedIds)
        }
        break
      }
      case 'BelongsTo': {
        const relatedIds = this.getInstanceValuesByKey(instances, field.ownerKey)
        result = await field.parent.fetchBatch(relatedIds)
        break
      }
    }

    return this.getInstancesFromResponse(result)
  }

  static isEmptyArray(arr) {
    return Array.isArray(arr) && arr.length === 0
  }

  static getRelationshipType(field) {
    return field?.constructor?.name
  }

  static getFieldByInstanceAndName(instance, fieldName) {
    return instance.constructor.fields()[fieldName]
  }

  static getInstanceValuesByKey(instances, key) {
    return [...new Set(instances.map(instance => instance[key]))]
  }

  static async fetchBatch(ids, deleteAll = false) {
    if (!ids) {
      return
    }

    if (deleteAll) {
      this.deleteAll()
    }

    const url = `${this.entity}?ids=${ids.toString()}`

    return this.api().get(url)
  }

  static fetchByKey(key, id, deleteAll = false, config) {
    if (key && id) {
      if (deleteAll) {
        this.deleteAll()
      }

      const url = `${this.entity}?key=${key}&id=${id}`
      return this.api().get(url, config)
    }
  }

  static fetchBatchByKey(key, ids, deleteAll = false) {
    if (key && ids) {
      if (deleteAll) {
        this.deleteAll()
      }

      return this.api().get(`${this.entity}?key=${key}&ids=${ids.toString()}`)
    }
  }

  static async fetchPaginated(params, deleteAll = false, relationships) {
    const searchQuery = `&q=${JSON.stringify(params.search)}`
    let order = ''
    if (params.sort.sortBy) {
      //make sure that we are trying to order by a valid field
      const matchingField = this.fields()[params.sort.sortBy]
      if (matchingField.constructor.name == 'String' || matchingField.constructor.name == 'Attr') {
        order = `&order=${params.sort.sortBy}${params.sort.sortDesc ? '.desc' : '.asc'}`
      }
    }

    if (deleteAll) {
      this.deleteAll()
    }

    const url = `${this.entity}?per_page=${params.pagination.per_page}&page=${params.pagination.page}${order}${searchQuery}`

    const response = await this.api().get(url, { dataKey: 'data' })

    const instances = this.getInstancesFromResponse(response)

    await this.fetchRelationships(instances, relationships)

    return response
  }

  static async fetchBatchByFirstField(ids, deleteAll = false) {
    if (!ids) {
      return
    }

    if (deleteAll) {
      this.deleteAll()
    }

    const url = `${this.entity}?id1=${ids.toString()}`
    return this.api().get(url)
  }

  static async fetchBatchBySecondField(ids, deleteAll = false) {
    if (deleteAll) {
      this.deleteAll()
    }

    const url = `${this.entity}?id2=${ids.toString()}`
    return this.api().get(url)
  }

  static updateOne(id, data) {
    const url = `${this.entity}/${id}`
    return this.api().put(url, data)
  }

  static deleteOne(id) {
    const url = `${this.entity}/${id}`
    return this.api().delete(url, { delete: id })
  }

  static deleteOnePivot(id1, id2) {
    const url = `${this.entity}/${id1}/${id2}`
    return this.api().delete(url, { delete: [id1, id2] })
  }

  delete() {
    const url = this.constructor.entity

    return this.constructor
      .api()
      .delete(url, { params: this.primaryKey.key, delete: JSON.parse(this.$id) })
  }
}
