<template>
  <v-sheet v-if="contract" tile @hook:mounted="!contract.contract_id && createOrUpdate(contract)">
    <v-card min-height="400" tile>
      <v-card-title class="headline primary white--text"
        >Kontrakt
        <v-spacer />
        <v-btn v-if="!loading" dark icon @click="clearErrorAlertAndEmitCloseAndReset">
          <v-icon>close</v-icon>
        </v-btn>
        <v-progress-circular v-else indeterminate color="white" size="36"></v-progress-circular>
      </v-card-title>
      <v-card-text class="mt-5">
        <div class="mb-5">
          <v-btn
            color="primary"
            tile
            depressed
            :outlined="selectedWindowItem != 1"
            @click="selectedWindowItem = 1"
            >Kontrakt
          </v-btn>
          <v-btn
            class="mx-0"
            color="primary"
            tile
            depressed
            :disabled="loading || !contract.contract_id"
            :outlined="selectedWindowItem != 2"
            @click="selectedWindowItem = 2"
            >Produkter
          </v-btn>
          <v-btn
            class="mx-0"
            color="primary"
            tile
            depressed
            :disabled="loading || !contract.agreements || !contract.agreements.length"
            :outlined="selectedWindowItem != 3"
            @click="selectedWindowItem = 3"
            >Aksept
          </v-btn>
        </div>
        <v-window v-model="selectedWindowItem">
          <v-window-item :value="1">
            <v-form ref="contractForm">
              <v-row>
                <v-col cols="12" sm="6">
                  <v-select
                    v-model="contract.user_id"
                    :items="users"
                    item-value="user_id"
                    label="Selger"
                    required
                    :rules="[$validationRules.required]"
                    @change="handleUserField"
                  >
                    <template v-slot:selection="user">{{ user.item.full_name }} </template>
                    <template v-slot:item="user">{{ user.item.full_name }} </template>
                  </v-select>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-autocomplete
                    ref="companies"
                    v-model="contract.company"
                    :items="companies"
                    label="Bedrift"
                    return-object
                    item-text="name"
                    no-data-text="Fant ingen bedrifter som matcher søket"
                    :search-input.sync="search.companies"
                    required
                    :rules="[$validationRules.required]"
                    :disabled="loading || disabledFormFields.includes('company')"
                    @change="handleCompanyField"
                  >
                    <template v-slot:item="company">
                      {{ company.item.name }}
                      <span class="orgnr">{{ company.item.orgnr | orgnr }}</span>
                    </template>

                    <template v-slot:selection="company">
                      {{ company.item.name }}
                      <span class="orgnr">{{ company.item.orgnr | orgnr }}</span>
                    </template>

                    <template slot="append-item">
                      <v-btn
                        color="primary"
                        text
                        small
                        class="mt-2 ml-2"
                        :disabled="search.loading"
                        @click="
                          showCompanyDialog({
                            fields: {
                              user_id: 1, //TODO replace with currently logged in user
                            },
                          })
                        "
                      >
                        <v-icon>add</v-icon>Opprett ny bedrift
                      </v-btn>
                      <v-progress-circular
                        v-if="search.loading"
                        indeterminate
                        size="24"
                        class="ml-2"
                      >
                      </v-progress-circular>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="contract.billing_address"
                    label="Postadresse"
                    required
                    :disabled="loading || !contract.company"
                    :rules="[$validationRules.required]"
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-select
                    v-model="contract.contactperson"
                    :items="contract.company.contactpersons"
                    label="Kontaktperson"
                    item-value="contactperson_id"
                    return-object
                    required
                    :disabled="loading || !contract.company"
                    :rules="[$validationRules.required]"
                    @change="handleContactpersonField"
                  >
                    <template v-slot:selection="contactperson">
                      {{ contactperson.item.full_name }}
                    </template>
                    <template v-slot:item="contactperson">
                      {{ contactperson.item.full_name }}
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-select
                    v-model="contract.contact_phone"
                    :items="phonenumbers"
                    label="Telefon"
                    return-object
                    no-data-text="Fant ingen telefonnummer"
                    required
                    :disabled="loading || !contract.contactperson"
                    :rules="[$validationRules.required]"
                    @change="handleContactInformation"
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-select
                    v-model="contract.contact_email"
                    :items="emailaddresses"
                    label="E-post"
                    return-object
                    no-data-text="Fant ingen e-postadresser"
                    required
                    :disabled="loading || !contract.contactperson"
                    :rules="[$validationRules.required]"
                    @change="handleContactInformation"
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-textarea
                    v-model="contract.note"
                    label="Merknad"
                    clearable
                    rows="1"
                    auto-grow
                    :disabled="loading"
                    @blur="handleNoteField"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-window-item>
          <v-window-item :value="2">
            <h3>Produkter</h3>
            <div
              v-for="agreement in contract.agreements"
              :key="agreement.agreement_id"
              class="product mb-2 pa-2 grey lighten-4"
            >
              <strong>{{ agreement.name }}</strong>
              <!-- eslint-disable-next-line -->
              <div class="my-1" v-html="agreement.description" />
              <p v-if="agreement.duration_months > 0">
                Avtaleperiode: {{ agreement.duration_months }} måneder
              </p>
              <p v-if="!agreement.discount_percentage">
                Pris: {{ agreement.full_price | currency }}
              </p>
              <template v-else>
                <p>Full pris: {{ agreement.full_price | currency }}</p>
                <p>
                  {{ agreement.discount_percentage }}% rabatt: -{{
                    getDiscountAmount(agreement.full_price, agreement.discount_percentage)
                      | currency
                  }}
                </p>
                <p>
                  Pris:
                  {{
                    getDiscountedPrice(agreement.full_price, agreement.discount_percentage)
                      | currency
                  }}
                </p>
              </template>
              <v-btn text icon class="mx-0" @click="showAgreementDialog({ agreement: agreement })">
                <v-icon>edit</v-icon>
              </v-btn>
              <v-btn text icon color="error" class="mx-0" @click="deleteAgreement(agreement)">
                <v-icon>delete</v-icon>
              </v-btn>
            </div>
            <a class="add-item-link" @click="showAgreementDialog()">Legg til nytt produkt</a>
          </v-window-item>
          <v-window-item :value="3">
            <h3>Aksept</h3>
            <v-form ref="acceptanceForm">
              <v-text-field
                v-model="acceptanceTimestamp"
                label="Akseptert dato"
                required
                :disabled="loading"
                clearable
                :rules="[$validationRules.datetimeNorway]"
              />
              <v-text-field
                v-model="contract.signed_contract_url"
                label="Url til signert kontrakt"
                required
                :disabled="loading"
                clearable
                @change="handleUrlField"
              />
            </v-form>
          </v-window-item>
        </v-window>
        <ErrorAlert v-bind="errorAlert" />
      </v-card-text>
      <v-card-actions class="grey--lighten-3 pl-5">
        <v-spacer />
        <v-btn
          text
          color="error"
          :disabled="loading || !contract.contract_id || contract.acceptance != undefined"
          @click="deleteContract"
        >
          <v-icon>delete</v-icon>Slett kontrakt
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-snackbar v-model="saveContractSuccess" :timeout="2500" color="success"
      >Kontraktinfo lagret
    </v-snackbar>

    <AgreementDialog
      :show="agreementDialog.show"
      :agreement="agreementDialog.agreement"
      @setNewEntity="setNewAgreement"
      @closeDialog="closeAgreementDialog"
    />
    <CompanyDialog
      :show="companyDialog.show"
      :company="companyDialog.company"
      @closeAndReset="closeAndResetCompanyDialog"
      @setNewEntity="setNewCompany"
    />
  </v-sheet>
</template>

<script>
import debounce from 'debounce'
import moment from 'moment'
import agreementDialog from '@/mixins/agreementDialog'
import companyDialog from '@/mixins/companyDialog'
import combineArrays from '@/mixins/combineArrays'
import entityPersistence from '@/mixins/entityPersistence'
import responseHelper from '@/mixins/responseHelper'
import validationRules from '@/mixins/validationRules'
import Acceptance from '@/models/Acceptance'
import Agreement from '@/models/Agreement'
import Contract from '@/models/Contract'
import Company from '@/models/Company'
import User from '@/models/User'
import ErrorAlert from './ErrorAlert'
import AgreementDialog from './AgreementDialog'
import CompanyDialog from '@/components/CompanyDialog'

export default {
  name: 'EditContractSheet',
  components: {
    ErrorAlert,
    AgreementDialog,
    CompanyDialog,
  },
  mixins: [
    agreementDialog,
    combineArrays,
    companyDialog,
    entityPersistence,
    responseHelper,
    validationRules,
  ],
  props: {
    show: Boolean,
    contract: {
      type: Contract,
      default: null,
    },
    disabledFormFields: {
      type: Array,
      default: () => [],
    },
  },
  data: () => {
    return {
      saveContractSuccess: false,
      selectedWindowItem: 1,
      loading: false,
      errorAlert: {
        show: false,
        errors: [],
      },
      agreementDialog: {
        show: false,
        agreement: null,
      },
      search: {
        companies: null,
        loading: false,
      },
      companies: [],
    }
  },
  computed: {
    acceptanceTimestamp: {
      get() {
        if (!this.contract?.acceptance?.timestamp) return ''

        const timestamp = moment(this.contract?.acceptance?.timestamp, 'YYYY-MM-DD HH:mm:ss')
        if (!timestamp.isValid()) return ''

        return timestamp.format('DD.MM.YYYY HH:mm:ss')
      },
      set(value) {
        if (!this.$refs.acceptanceForm?.validate()) return
        if (value === null && !this.contract?.acceptance?.acceptance_id) return

        if (value === null) {
          this.handleAcceptedDateField(value)
          return
        }

        const timestamp = moment(value, 'DD.MM.YYYY HH:mm:ss')
        if (timestamp.isValid()) {
          this.handleAcceptedDateField(timestamp.format('YYYY-MM-DD HH:mm:ss'))
        }
      },
    },
    phonenumbers() {
      let phonenumbersForContactperson = []

      if (this.contract?.contactperson?.phonenumbers) {
        phonenumbersForContactperson = this.contract.contactperson.phonenumbers.map(item => {
          return item.phonenumber
        })
      }

      if (this.contract?.contact_phone) {
        return this.combineArraysWithoutDuplicates(
          [this.contract.contact_phone],
          phonenumbersForContactperson
        )
      }

      return phonenumbersForContactperson
    },

    emailaddresses() {
      let emailaddressesForContactperson = []

      if (this.contract?.contactperson?.emailaddresses) {
        emailaddressesForContactperson = this.contract.contactperson.emailaddresses.map(item => {
          return item.emailaddress
        })
      }

      if (this.contract?.contact_email) {
        return this.combineArraysWithoutDuplicates(
          [this.contract.contact_email],
          emailaddressesForContactperson
        )
      }

      return emailaddressesForContactperson
    },

    users() {
      return User.query().get()
    },
  },
  watch: {
    show: function(show) {
      if (!show) {
        this.selectedWindowItem = 1
      }
    },
    'search.companies': {
      handler: debounce(async function(searchFor) {
        console.log('search.companies watcher')
        this.companies = []
        if (this.contract.company) {
          this.companies.push(this.contract.company)
        }

        // this.clearErrorAlert()
        if (!searchFor || searchFor.length < 3) {
          return
        }

        try {
          const searchResult = await this.fetchCompanies(searchFor)
          if (searchResult) {
            this.companies = [...this.companies, ...searchResult]
          }
        } catch (e) {
          this.handleError(e.message)
        }
      }, 250),
    },
  },
  created() {
    if (this.contract?.company) {
      this.companies.push(this.contract.company)
    }
  },
  methods: {
    closeAndResetCompanyDialog() {
      this.companyDialog.show = false
      this.companyDialog.company = null
      this.handleCompanyField()
    },
    async handleCompanyField() {
      console.log('contract.company handler')
      this.contract.contactperson = null

      try {
        this.contract.billing_address = this.contract.company.billing_address
        await this.fetchContactpersonsForSelectedCompany()
      } catch (e) {
        this.handleError([e.message, 'Kunne ikke hente informasjon om bedriften'])
      }
    },
    async handleUserField() {
      await this.createOrUpdate({ user_id: this.contract.user_id })
    },
    async handleUrlField() {
      await this.createOrUpdate({ signed_contract_url: this.contract.signed_contract_url })
    },
    async handleAcceptedDateField(timestamp) {
      this.clearErrorAlert()

      const contractId = this.contract?.contract_id
      if (!contractId) return

      const acceptanceId = this.contract?.acceptance?.acceptance_id

      this.loading = true

      try {
        if (!acceptanceId) {
          await Acceptance.createOne({
            contract_id: contractId,
            timestamp: timestamp,
            acceptancetype_id: 7, //hard coded value for simplification
          })
        } else if (timestamp === null) {
          await Acceptance.deleteOne(acceptanceId)
        } else {
          await Acceptance.updateOne(acceptanceId, { timestamp: timestamp })
        }

        this.$emit('acceptanceChanged', this.contract)
      } catch (e) {
        this.handleError(e.message)
      } finally {
        this.loading = false
      }
    },
    async handleContactpersonField() {
      const { contactperson } = this.contract

      this.clearContactFields()

      if (!contactperson) return

      const { contactperson_id } = contactperson
      this.setContactFields(contactperson)

      try {
        await this.createOrUpdate({
          contactperson_id,
          contact_name: this.contract.contact_name,
          contact_email: this.contract.contact_email,
          contact_phone: this.contract.contact_phone,
        })
      } catch (e) {
        this.handleError(e.message)
      }
    },

    setContactFields(contactperson) {
      if (!contactperson) return
      const { full_name, firstEmailaddress, firstPhonenumber } = contactperson

      this.contract.contact_phone = firstPhonenumber?.phonenumber
      this.contract.contact_email = firstEmailaddress?.emailaddress
      this.contract.contact_name = full_name
    },

    clearContactFields() {
      this.contract.contact_phone = null
      this.contract.contact_email = null
      this.contract.contact_name = null
    },

    async handleContactInformation() {
      console.log('contract.phonenumber and emailaddress handler')
      await this.$nextTick() //so that DOM is updated before trying to validate
      await this.createOrUpdate({
        contact_phone: this.contract.contact_phone,
        contact_email: this.contract.contact_email,
        contact_name: this.contract.contact_name,
        contactperson_id: this.contract.contactperson.contactperson_id,
      })
    },

    async handleNoteField() {
      await this.$nextTick() //so that DOM is updated before trying to validate
      await this.createOrUpdate({ note: this.contract.note })
    },

    async createOrUpdate(data) {
      if (!this.$refs.contractForm?.validate()) {
        return
      }

      this.clearErrorAlert()
      if (!this.contract.contract_id) {
        data = {
          contactperson_id: this.contract.contactperson.contactperson_id,
          company_id: this.contract.company.company_id,
          user_id: this.contract.user_id,
          billing_address: this.contract.billing_address,
          contact_name: this.contract.contact_name,
          contact_phone: this.contract.contact_phone,
          contact_email: this.contract.contact_email,
        }
      }
      await this.createOrUpdateEntity(Contract, data, this.contract.contract_id)
    },
    async fetchCompanies(searchFor) {
      if (!searchFor) {
        return
      }

      let result
      try {
        this.search.loading = true
        result = await Company.fetchAll({ search: { name: searchFor } })
      } catch (e) {
        this.closeCompanyMenu()
        this.handleError([e.message, 'Kunne ikke søke etter bedrifter'])
      } finally {
        this.search.loading = false
      }
      return result.entities.companies
    },

    async closeCompanyMenu() {
      this.$refs.companies.isMenuActive = false
    },

    async deleteContract() {
      try {
        await Contract.deleteOne(this.contract.contract_id)
        this.$emit('deletedContract')
      } catch (e) {
        this.handleError(`Kunne ikke slette kontrakt: ${e.message}`)
        return
      }
    },
    setNewAgreement(agreement) {
      this.contract.agreements.push(agreement)
      this.agreementDialog.agreement = agreement
      console.log('agreement:', agreement)
    },

    async setNewCompany(company) {
      this.companyDialog.company = company
      this.companies.push(company)
      this.contract.company = company
    },
    updateContractContactinfo(contactperson) {
      if (contactperson.first_name && contactperson.last_name) {
        this.contract.contact_name = `${contactperson.first_name} ${contactperson.last_name}`
      }
      if (contactperson.phonenumbers && contactperson.phonenumbers.length > 0) {
        this.contract.contact_phone =
          contactperson.phonenumbers[contactperson.phonenumbers.length - 1].phonenumber
      }
      if (contactperson.emailaddresses && contactperson.emailaddresses.length > 0) {
        this.contract.contact_email =
          contactperson.emailaddresses[contactperson.emailaddresses.length - 1].emailaddress
      }
    },
    async deleteAgreement(agreement) {
      try {
        //await deleteEntity(Agreement, agreement.id)

        console.log('Agreement:', Agreement)
        this.$emit('deletedAgreement', agreement)
        this.contract.agreements = this.contract.agreements.filter(item => {
          return item.id != agreement.id
        })
      } catch (e) {
        this.handleError(`Kunne ikke slette avtale: ${e.message}`)
        return
      }
    },
    getDiscountAmount(fullPrice, discountPercentage) {
      return fullPrice * (discountPercentage / 100)
    },
    getDiscountedPrice(fullPrice, discountPercentage) {
      return fullPrice * (1 - discountPercentage / 100)
    },
    handleError(message) {
      this.showErrorAlert(message)
      this.loading = false
    },
    showErrorAlert(message) {
      this.errorAlert.errors.push(message)
      this.errorAlert.show = true
    },
    clearErrorAlertAndEmitCloseAndReset() {
      this.clearErrorAlert()
      this.$emit('closeAndReset')
    },
    clearErrorAlert() {
      this.errorAlert.errors = []
      this.errorAlert.show = false
    },
  },
}
</script>

<style scoped lang="less">
.product p {
  margin-bottom: 0;

  &:last-child {
    font-weight: bold;
  }
}

.orgnr {
  font-size: 0.8em;
  color: rgba(0, 0, 0, 0.5);
  margin-left: 0.5em;
}
</style>
