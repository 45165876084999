<template>
  <v-container>
    <v-layout wrap>
      <v-flex xs12>
        <v-alert
          v-model="generalErrorAlert"
          dismissible
          type="error"
          transition="slide-y-transition"
          @input="generalErrorMsg = ''"
          >{{ generalErrorMsg }}
        </v-alert>
        <v-toolbar class="secondary" dark>
          <v-toolbar-title>Kontrakter</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="showEditContractSheet()">
            <v-icon>add</v-icon>
          </v-btn>
        </v-toolbar>

        <!-- filters ----------------------------------------------------------------->
        <v-layout class="white mx-0 pa-2">
          <v-icon>filter_list</v-icon>
          <v-flex xs2 class="mr-2 ml-4">
            <!-- <v-text-field v-model="dataTable.search.name" label="Produkt" clearable /> -->
          </v-flex>
          <v-flex xs2 class="mr-2">
            <!-- <v-text-field v-model="dataTable.search.description" label="Innhold" clearable /> -->
          </v-flex>
          <v-flex xs2 class="mr-2">
            <!-- <v-text-field label="Produkt" clearable /> -->
          </v-flex>
        </v-layout>

        <!-- contracts table ------------------------------------------------------->
        <v-data-table
          :headers="dataTableHeaders"
          :items="contracts"
          :loading="loading"
          item-key="id"
          class="expandable-table"
          :footer-props="dataTable.footerProps"
          :server-items-length="dataTable.totalItems"
          loading-text="Henter data"
          disable-sort
          @update:options="updateDatatableOptions"
        >
          <template v-slot:item="contracts">
            <tr class="cursorPointer" @click="showContractDialog(contracts.item)">
              <td class="white-space-nowrap">
                <template v-if="contracts.item.company">{{ contracts.item.company.name }}</template>
              </td>
              <td class="white-space-nowrap">
                <template v-if="contracts.item.agreements.length > 0">
                  <span v-for="agreement in contracts.item.agreements" :key="agreement.id">
                    <v-chip v-if="agreement.agreementtype" class="mr-1">
                      {{ agreement.agreementtype.name }}
                      {{
                        getDiscountedPrice(agreement.full_price, agreement.discount_percentage)
                          | currency
                      }}
                    </v-chip>
                  </span>
                </template>
              </td>
              <td class="white-space-nowrap">
                {{ getTotalAmount(contracts.item.agreements) | currency }}
              </td>
              <td class="white-space-nowrap">
                <template v-if="contracts.item.user">
                  {{ contracts.item.user.first_name }}
                  {{ contracts.item.user.last_name }}
                </template>
              </td>
              <td>
                <template v-if="contracts.item.acceptance">
                  {{ contracts.item.acceptance.timestamp | moment('DD.MM.YYYY') }}
                </template>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>

    <v-bottom-sheet v-model="editContractSheet.show" inset persistent max-width="1000px">
      <EditContractSheet
        v-bind="editContractSheet"
        @closeAndReset="showContractDialogAndCloseAndResetEditContractSheet"
        @setNewEntity="setNewContract"
        @deletedContract="closeAndResetEditContractSheet"
      />
    </v-bottom-sheet>

    <ContractDialog v-bind="contractDialog" @closeAndReset="closeAndResetContractDialog" />
  </v-container>
</template>

<script>
import amounts from '@/mixins/amounts'
import updateDatatableOptions from '@/mixins/dataTable'
import editContractSheet from '@/mixins/editContractSheet'
import responseHelper from '@/mixins/responseHelper'
import ContractDialog from '@/components/ContractDialog'
import EditContractSheet from '@/components/EditContractSheet'
import Acceptance from '@/models/Acceptance'
import Agreement from '@/models/Agreement'
import Company from '@/models/Company'
import Contactperson from '@/models/Contactperson'
import Contract from '@/models/Contract'

export default {
  components: {
    EditContractSheet,
    ContractDialog,
  },
  mixins: [amounts, editContractSheet, updateDatatableOptions, responseHelper],
  data: () => {
    return {
      loading: false,
      generalErrorMsg: '',
      generalErrorAlert: false,
      dataTableHeaders: [
        { text: 'Bedrift', value: 'contract' },
        { text: 'Produkter', value: 'products' },
        { text: 'Beløp', value: 'amount' },
        { text: 'Selger', value: 'user' },
        { text: 'Inngått', value: 'signed' },
      ],
      contractDialog: {
        show: false,
        contract: null,
      },
    }
  },
  computed: {
    contracts() {
      return Contract.query()
        .with('company')
        .with('user')
        .with('agreements')
        .with('agreements.agreementtype')
        .with('acceptance')
        .with('acceptance.acceptancetype')
        .with('contactperson')
        .with('our_contactinfo')
        .get()
    },
  },
  methods: {
    setNewContract(contract) {
      this.editContractSheet.contract = Contract.query()
        .whereId(contract.contract_id)
        .withAll()
        .with('company.contactpersons')
        .with('contactperson.phonenumbers')
        .with('contactperson.emailaddresses')
        .first()
    },

    async fetchData() {
      this.loading = true

      const contracts = await Contract.fetchPaginated({
        pagination: this.dataTable.pagination,
        sort: this.dataTable.sort,
        search: this.dataTable.search,
      })

      this.dataTable.totalItems = contracts.response.data.total

      const contractIds = this.getIdsFromEntities(contracts, 'contract_id')
      const companyIds = this.getIdsFromEntities(contracts, 'company_id')
      const contactpersonIds = this.getIdsFromEntities(contracts, 'contactperson_id')

      Acceptance.fetchBatchByKey('contract_id', contractIds)
      Agreement.fetchBatchByKey('contract_id', contractIds)
      Company.fetchBatch(companyIds)
      Contactperson.fetchBatch(contactpersonIds)

      this.loading = false
    },
    showContractDialogAndCloseAndResetEditContractSheet() {
      this.showContractDialog(this.editContractSheet.contract)
      this.closeAndResetEditContractSheet()
    },
    showContractDialog(contract) {
      if (contract && !contract.contract_id) {
        return
      }

      if (!contract) {
        contract = {
          user_id: 1,
        }
      }

      this.contractDialog.show = true
      this.contractDialog.contract = contract
    },
    closeAndResetContractDialog() {
      this.contractDialog.show = false
      this.contractDialog.contract = null
    },
  },
}
</script>
