<template>
  <div class="comment">
    <div class="comment__header d-flex justify-space-between mb-1 mb-sm-3">
      <div class="comment__meta d-sm-flex">
        <div class="comment__author d-flex mr-2 mb-1 align-center">
          <v-avatar color="orange" class="black--text mr-2" size="23">{{
            comment.user.first_name | strLeft(1)
          }}</v-avatar>
          <div class="comment__user grey--text text-uppercase text--darken-1 font-weight-bold">
            {{ comment.user.first_name }}
          </div>
        </div>
        <div class="comment__timestamp grey--text">
          {{ comment.created_timestamp | moment('DD.MM.YYYY HH:mm') }}
        </div>
      </div>
      <div class="comment__actions text-caption">
        <v-btn x-small icon class="mr-1"><v-icon @click="showAddComment">reply</v-icon></v-btn>
        <v-btn
          v-if="comment.user_id == $currentUser.user_id"
          x-small
          icon
          class="mr-1"
          :disabled="comment.deleted_timestamp != null"
          ><v-icon @click="showEditComment">edit</v-icon></v-btn
        >
        <v-btn
          v-if="comment.user_id == $currentUser.user_id"
          color="error"
          :disabled="comment.deleted_timestamp != null"
          x-small
          icon
          ><v-icon @click="deleteComment(comment)">delete_forever</v-icon></v-btn
        >
      </div>
    </div>

    <v-progress-linear v-if="loading" indeterminate />

    <div class="comment__content d-flex justify-space-between">
      <div v-if="!editComment" class="comment__text grey--text text--darken-1">
        <template v-if="showDeletedComment || !comment.deleted_timestamp"
          >{{ comment.text }}
          <div v-if="comment.edited_timestamp" class="mt-2 font-italic caption">
            Redigert {{ comment.edited_timestamp | moment('DD.MM.YYYY HH:mm') }}
          </div></template
        >
        <div v-if="comment.deleted_timestamp" class="font-italic caption">
          Slettet {{ comment.deleted_timestamp | moment('DD.MM.YYYY HH:mm') }}
          <v-btn
            v-if="$currentUser.hasPermission('SHOW_DELETED_COMMENTS')"
            small
            icon
            class="text-caption"
            @click="showDeletedComment = !showDeletedComment"
          >
            <v-icon v-if="showDeletedComment" size="16">visibility_off</v-icon>
            <v-icon v-else size="16">visibility</v-icon>
          </v-btn>
        </div>
      </div>
      <div v-else class="d-flex align-center">
        <v-textarea
          ref="editCommentInput"
          v-model="editedComment"
          rows="1"
          auto-grow
          label="Rediger kommentar"
          variant="underlined"
          class="mr-1"
          :error-messages="editCommentError"
        />
        <v-btn :disabled="loading" icon color="error" @click="clearEditComment"
          ><v-icon>close</v-icon>
        </v-btn>
        <v-btn
          :disabled="loading || editedComment == comment.text"
          icon
          color="success"
          @click="saveEditedComment"
          ><v-icon>send</v-icon>
        </v-btn>
      </div>

      <v-btn v-if="comment.children.length" icon small @click="showChildren = !showChildren">
        <template v-if="showChildren">-</template><template v-else>+</template
        >{{ comment.children.length }}
      </v-btn>
    </div>

    <div v-if="comment.children.length && showChildren" class="comment__children pl-8">
      <Comment
        v-for="(child, i) in comment.children"
        :key="child.companycomment_id"
        :comment-id="child.companycomment_id"
        class="my-6 grey--text text--darken-3"
        :class="i == comment.children.length - 1 ? 'mb-0' : ''"
      />
    </div>

    <div v-if="addComment" class="d-flex align-center">
      <v-textarea
        ref="addCommentInput"
        v-model="addedComment"
        rows="1"
        auto-grow
        label="Svar på kommentar"
        variant="underlined"
        class="mr-1"
        :error-messages="addCommentError"
      />
      <v-btn :disabled="loading" icon color="error" @click="clearAddedComment"
        ><v-icon>close</v-icon>
      </v-btn>
      <v-btn :disabled="loading || !addedComment" icon color="success" @click="saveAddedComment"
        ><v-icon>send</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import Companycomment from '@/models/Companycomment.js'
import currentUser from '@/mixins/currentUser.js'
import dateAndTime from '@/mixins/dateAndTime.js'

export default {
  name: 'Comment',
  components: {},
  mixins: [currentUser, dateAndTime],
  props: {
    commentId: {
      type: Number,
      default: null,
    },
  },
  data: () => {
    return {
      loading: false,
      addComment: false,
      addCommentError: null,
      addedComment: null,
      editComment: false,
      editCommentError: null,
      editedComment: null,
      showChildren: true,
      showDeletedComment: false,
    }
  },
  computed: {
    comment() {
      return Companycomment.query()
        .with('children')
        .with('user')
        .whereId(this.commentId)
        .first()
    },
  },
  methods: {
    async deleteComment() {
      try {
        this.loading = true
        await this.comment.softDelete()
      } catch (err) {
        console.error(err)
      } finally {
        this.loading = false
      }
    },
    showAddComment() {
      this.addComment = true
      this.$nextTick(() => {
        this.$refs.addCommentInput.focus()
      })
    },
    clearAddedComment() {
      this.addedComment = null
      this.addComment = false
    },
    async saveAddedComment() {
      try {
        this.loading = true

        const addedComment = {
          company_id: this.comment.company_id,
          user_id: this.$currentUser.user_id,
          parent_id: this.comment.companycomment_id,
          text: this.addedComment,
        }

        await Companycomment.createOne(addedComment)
        this.clearAddedComment()
      } catch (err) {
        this.addCommentError = err.message
      } finally {
        this.loading = false
      }
    },
    showEditComment() {
      this.editedComment = this.comment.text
      this.editComment = true
      this.$nextTick(() => {
        this.$refs.editCommentInput.focus()
      })
    },
    clearEditComment() {
      this.editComment = false
      this.editCommentError = null
      this.editedComment = null
    },
    async saveEditedComment() {
      try {
        this.loading = true

        if (!this.editedComment) {
          this.comment.softDelete()
        } else {
          const editedComment = { text: this.editedComment }
          await this.comment.update(editedComment)
        }
        this.clearEditComment()
      } catch (err) {
        this.editCommentError = err.message
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="less" scoped>
.comment {
  &__text {
    white-space: pre-line;
  }
}
</style>
