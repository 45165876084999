<template>
  <div class="text-center">
    <v-progress-circular indeterminate color="primary" :size="70"></v-progress-circular>
  </div>
</template>

<script>
export default {
  created() {
    this.$auth.handleAuthentication()
  },
  methods: {
    handleLoginEvent(data) {
      this.$router.push(data.state.target || '/')
    },
  },
}
</script>
