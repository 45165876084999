import { VForm } from 'vuetify/lib/components/VForm';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.entity)?_c('BaseDialog',{ref:"baseDialog",attrs:{"show":_vm.show,"entity":_vm.entity,"create-title":_vm.createTitle,"edit-title":_vm.editTitle,"max-width":_vm.maxWidth,"loading":_vm.loading},on:{"closeAndReset":_vm.closeDialog}},[_c('template',{slot:"form"},[_c(VForm,{ref:"form",attrs:{"autocomplete":"off"}},_vm._l((_vm.fields),function(field){return _c('span',{key:field.name},[(field.type == 'text')?_c(VTextField,{attrs:{"label":field.label,"hint":field.hint,"persistent-hint":field.persistentHint,"rules":field.rules,"disabled":_vm.loading || field.disabled},on:{"change":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleField.apply(void 0, [ field.name ].concat( argsArray ))}},model:{value:(_vm.entity[field.name]),callback:function ($$v) {_vm.$set(_vm.entity, field.name, $$v)},expression:"entity[field.name]"}}):_vm._e(),(field.type == 'textarea')?_c(VTextarea,{attrs:{"label":field.label,"hint":field.hint,"persistent-hint":field.persistentHint,"rows":field.rows,"auto-grow":field.autoGrow,"rules":field.rules,"disabled":_vm.loading || field.disabled,"messages":field.messages},on:{"change":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleField.apply(void 0, [ field.name ].concat( argsArray ))}},model:{value:(_vm.entity[field.name]),callback:function ($$v) {_vm.$set(_vm.entity, field.name, $$v)},expression:"entity[field.name]"}}):_vm._e(),(field.type == 'lookupAutocomplete')?_c('LookupAutocomplete',{attrs:{"label":field.label,"rules":field.rules,"loading":_vm.loading,"disabled":_vm.loading || field.disabled,"item":field.item,"item-value":field.itemValue,"item-text":field.itemText,"filters":field.filters,"model":field.model},on:{"input":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
field.inputHandler
              ? field.inputHandler.apply(field, [ field.name ].concat( argsArray ))
              : _vm.handleField.apply(void 0, [ field.name ].concat( argsArray ))}},model:{value:(_vm.entity[field.name]),callback:function ($$v) {_vm.$set(_vm.entity, field.name, $$v)},expression:"entity[field.name]"}}):_vm._e(),(field.type == 'color')?_c('ColorSelect',{attrs:{"color":_vm.entity[field.name],"label":field.label,"rules":field.rules,"disabled":_vm.loading || field.disabled},on:{"input":function($event){
              var i = arguments.length, argsArray = Array(i);
              while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleField.apply(void 0, [ field.name ].concat( argsArray ))}},model:{value:(_vm.entity[field.name]),callback:function ($$v) {_vm.$set(_vm.entity, field.name, $$v)},expression:"entity[field.name]"}}):_vm._e(),(field.type == 'select')?_c(VSelect,{attrs:{"label":field.label,"items":field.items,"item-text":field.itemText,"item-value":field.itemValue,"no-data-text":field.noDataText,"rules":field.rules,"clearable":field.clearable,"disabled":_vm.loading || field.disabled},on:{"change":function($event){
              var i = arguments.length, argsArray = Array(i);
              while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleField.apply(void 0, [ field.name ].concat( argsArray ))}},model:{value:(_vm.entity[field.name]),callback:function ($$v) {_vm.$set(_vm.entity, field.name, $$v)},expression:"entity[field.name]"}}):_vm._e(),(field.type == 'switch')?_c(VSwitch,{attrs:{"label":field.label,"disabled":_vm.loading || field.disabled},on:{"change":function($event){
              var i = arguments.length, argsArray = Array(i);
              while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleField.apply(void 0, [ field.name ].concat( argsArray ))}},model:{value:(_vm.entity[field.name]),callback:function ($$v) {_vm.$set(_vm.entity, field.name, $$v)},expression:"entity[field.name]"}}):_vm._e()],1)}),0)],1)],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }