export const getIdsFromEntities = (response, field) => {
  if (!response) return
  if (!response.entities[response.model.entity]) return

  return response.entities[response.model.entity].map(entity => entity[field])
}

export const getFirstReturnedInstance = response => {
  if (!response) return
  if (!response.entities[response.model.entity]) return

  return response.entities[response.model.entity]?.at(0)
}

export default {
  methods: {
    getIdsFromEntities,
  },
}
