export default {
  data: () => {
    return {
      errorAlert: {
        show: false,
        errors: [],
      },
    }
  },
  methods: {
    clearErrorAlert() {
      this.errorAlert.errors = []
      this.errorAlert.show = false
    },

    showErrorAlert(message) {
      if (Array.isArray(message)) {
        this.errorAlert.errors.push(...message)
      } else if (typeof message == 'string') {
        this.errorAlert.errors.push(message)
      }
      this.errorAlert.show = true
    },

    handleError(message) {
      this.showErrorAlert(message)
      this.loading = false
    },
  },
}
