import BaseModel from './BaseModel'
import User from './User'

export default class Companycomment extends BaseModel {
  static entity = 'companycomments'
  static primaryKey = 'companycomment_id'

  static fields() {
    return {
      companycomment_id: this.attr(null),
      company_id: this.attr(null),
      user_id: this.attr(null),
      parent_id: this.attr(null).nullable(),
      text: this.string(),
      created_timestamp: this.string(),
      edited_timestamp: this.string(null).nullable(),
      deleted_timestamp: this.string(null).nullable(),
      user: this.belongsTo(User, 'user_id', 'user_id'),
      children: this.hasMany(Companycomment, 'parent_id', 'companycomment_id'),
    }
  }

  async softDelete() {
    return Companycomment.api().delete(`${this.constructor.entity}/${this.companycomment_id}`)
  }
}
