import debounce from 'debounce'

export default {
  data: () => {
    return {
      dataTable: {
        footerProps: {
          'items-per-page-text': 'Vis:',
          'items-per-page-options': [25, 50, 100, 200, 500],
        },
        pagination: {
          per_page: 25,
          page: 1,
          total: 25,
        },
        sort: {
          sortBy: [],
          sortDesc: [],
        },
        search: {},
      },
    }
  },
  watch: {
    'dataTable.search': {
      handler: debounce(function() {
        this.fetchData()
      }, 1250),
      deep: true,
    },
  },
  methods: {
    updateDatatableOptions(e) {
      this.dataTable.pagination.per_page = e.itemsPerPage
      this.dataTable.pagination.page = e.page
      this.dataTable.sort.sortBy = e.sortBy[0]
      this.dataTable.sort.sortDesc = e.sortDesc[0]
      this.fetchData()
    },
  },
}
