<template>
  <v-dialog v-if="contract" value="show" width="700" scrollable @input="$emit('closeAndReset')">
    <v-card>
      <v-card-title class="primary white--text">
        Kontrakt
        <v-spacer></v-spacer>
        <v-btn icon dark @click="showEditContractSheet({ contract: contract })">
          <v-icon>edit</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pa-4 grey--text text--darken-3">
        <h2>Kontrakt - Medianord AS</h2>
        <dl class="my-5">
          <div>
            <dt>Firma</dt>
            <dd>{{ contract.company.name }}</dd>
          </div>
          <div>
            <dt>Org.nr</dt>
            <dd>{{ contract.company.orgnr | orgnr }}</dd>
          </div>
          <div>
            <dt>Postadresse</dt>
            <dd>{{ contract.billing_address }}</dd>
          </div>
          <div>
            <dt>Kontaktperson</dt>
            <dd>{{ contract.contact_name }}</dd>
          </div>
          <div>
            <dt>Telefon</dt>
            <dd>{{ contract.contact_phone | phone }}</dd>
          </div>
          <div>
            <dt>E-post</dt>
            <dd>{{ contract.contact_email }}</dd>
          </div>
        </dl>
        <pre class="note">{{ contract.note }}</pre>
        <table class="agreements-table my-5">
          <thead>
            <tr>
              <th class="py-2 border-bottom">Vareinformasjon</th>
              <th class="py-2 border-bottom">Pris</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="agreement in contract.agreements" :key="agreement.id" class="my-5">
              <td class="py-2 border-bottom">
                <strong>{{ agreement.name }}</strong>
                <!-- eslint-disable-next-line -->
                <div v-html="agreement.description" />
                <template v-if="agreement.discount_percentage">
                  <p>Full pris: {{ agreement.full_price | currency }}</p>
                  <p>
                    {{ agreement.discount_percentage }}% rabatt: -{{
                      getDiscountAmount(agreement.full_price, agreement.discount_percentage)
                        | currency
                    }}
                  </p>
                </template>
              </td>
              <td v-if="agreement.discount_percentage" class="py-2 border-bottom">
                {{
                  getDiscountedPrice(agreement.full_price, agreement.discount_percentage) | currency
                }}
              </td>
              <td v-else class="py-2 border-bottom">{{ agreement.full_price | currency }}</td>
            </tr>
            <tr>
              <td class="pt-2 text-right">Sum:</td>
              <td class="pt-2">{{ getTotalAmount(contract.agreements) | currency }}</td>
            </tr>
            <tr>
              <td class="pb-2 text-right">Mva:</td>
              <td class="pb-2 border-bottom">{{ getVAT(contract.agreements) | currency }}</td>
            </tr>
            <tr>
              <td class="py-2 text-right border-bottom">Totalt:</td>
              <td class="py-2 border-bottom">
                {{ getTotalInclVAT(contract.agreements) | currency }}
              </td>
            </tr>
          </tbody>
        </table>
        <v-row>
          <v-col>
            <template v-if="contract.our_contactinfo">
              <strong>Kontaktinfo:</strong>
              <pre>{{ contract.our_contactinfo.text }}</pre>
            </template>
          </v-col>
          <v-col cols="8">
            <v-card v-if="acceptance && contract.acceptance">
              <v-card-title class="success white--text">Aksept:</v-card-title>
              <v-card-text class="pt-3">
                <dl>
                  <div>
                    <dt>Dato / tid</dt>
                    <dd>{{ contract.acceptance.timestamp | moment('DD.MM.YYYY HH:mm:ss') }}</dd>
                  </div>
                  <div>
                    <dt>Type</dt>
                    <dd>{{ contract.acceptance.acceptancetype.name }}</dd>
                  </div>
                  <template v-if="contract.acceptance.acceptancetype.id == 1">
                    <div>
                      <dt>IP</dt>
                      <dd>{{ acceptance.ipaddress }}</dd>
                    </div>
                    <div>
                      <dt>Tekst</dt>
                      <dd>{{ acceptance.text }}</dd>
                    </div>
                  </template>
                  <template v-if="contract.acceptance.acceptancetype.id == 2">
                    <div>
                      <dt>Telefonnummer</dt>
                      <dd>{{ acceptance.phonenumber }}</dd>
                    </div>
                    <div>
                      <dt>Tekst</dt>
                      <dd>{{ acceptance.text }}</dd>
                    </div>
                    <div v-if="acceptance.screenshotpath">
                      <dt>Skjermdump</dt>
                      <dd>{{ acceptance.screenshotpath }}</dd>
                    </div>
                  </template>
                  <template v-if="contract.acceptance.acceptancetype.id == 6">
                    <div>
                      <dt>Beskrivelse</dt>
                      <dd>
                        <pre>{{ acceptance.description }}</pre>
                      </dd>
                    </div>
                  </template>
                </dl>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>

      <v-bottom-sheet v-model="editContractSheet.show" inset persistent max-width="1000px">
        <EditContractSheet
          v-bind="editContractSheet"
          @closeAndReset="closeAndResetEditContractSheet"
          @deletedContract="$emit('closeAndReset')"
          @deletedAgreement="removeAgreement"
        />
      </v-bottom-sheet>
    </v-card>
  </v-dialog>
</template>

<script>
import amounts from '@/mixins/amounts'
import editContractSheet from '@/mixins/editContractSheet'
import EditContractSheet from '@/components/EditContractSheet'
import Autoacceptance from '@/models/Autoacceptance'
import Contract from '@/models/Contract'
import Smsacceptance from '@/models/Smsacceptance'
import Webacceptance from '@/models/Webacceptance'

export default {
  name: 'ContractDialog',
  components: {
    EditContractSheet,
  },
  mixins: [amounts, editContractSheet],
  props: {
    show: Boolean,
    contract: {
      type: Contract,
      default: null,
    },
  },
  data: () => {
    return {
      acceptance: null,
    }
  },
  computed: {
    acceptance_id() {
      if (this.contract && this.contract.acceptance) {
        return this.contract.acceptance.id
      }
      return null
    },
  },
  watch: {
    acceptance_id: async function(newId, oldId) {
      if (newId && newId != oldId) {
        try {
          switch (this.contract.acceptance.acceptancetype.id) {
            case 1:
              this.acceptance = await Webacceptance.fetchOne(newId)
              this.acceptance = this.acceptance.entities.webacceptances[0]
              break
            case 2:
              this.acceptance = await Smsacceptance.fetchOne(newId)
              this.acceptance = this.acceptance.entities.smsacceptances[0]
              break
            case 6:
              this.acceptance = await Autoacceptance.fetchOne(newId)
              this.acceptance = this.acceptance.entities.autoacceptances[0]
              break
          }
        } catch (e) {
          console.log('Error getting Acceptance: ', e)
        }
      }
    },
  },
  methods: {
    removeAgreement(agreement) {
      this.contract.agreements = this.contract.agreements.filter(item => {
        return item.id != agreement.id
      })
    },
  },
}
</script>

<style scoped lang="less">
.note {
  font-style: italic;
}

dt,
dd {
  display: inline;
}

dt {
  font-weight: bold;

  &:after {
    content: ': ';
  }
}

.agreements-table {
  width: 100%;
  border-spacing: 0;

  th {
    text-align: left;
  }
  td {
    vertical-align: bottom;
  }
  td,
  th {
    &:last-child {
      text-align: right;
      white-space: nowrap;
    }
    &.border-bottom {
      border-bottom: 1px solid;
    }
  }
  .sum {
    text-align: right;
  }
}
</style>
