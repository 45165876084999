import BaseModel from './BaseModel'
import Contract from './Contract'
import User from './User'
import Agreementtype from './Agreementtype'
//import Renewalstatus from './Renewalstatus'

export default class Agreement extends BaseModel {
  static entity = 'agreements'
  static primaryKey = 'agreement_id'

  static fields() {
    return {
      agreement_id: this.attr(null),
      contract_id: this.attr(),
      contract: this.belongsTo(Contract, 'contract_id'),
      renews_agreement_id: this.attr(),
      renews_agreement: this.belongsTo(Agreement, 'renews_agreement_id'),
      renewal_user_id: this.attr(),
      renewal_user: this.belongsTo(User, 'user_id'),
      agreementtype_id: this.attr(),
      agreementtype: this.belongsTo(Agreementtype, 'agreementtype_id'),
      renewalstatus_id: this.attr(),
      //renewalstatus: this.belongsTo(Renewalstatus, 'renewalstatus_id'),
      name: this.string(),
      no_autorenewal: this.boolean(),
      cancelled_date: this.string(),
      description: this.string().nullable(),
      full_price: this.number(),
      discount_percentage: this.number(),
      duration_months: this.number(),
    }
  }
}
