<template>
  <v-container>
    <v-layout>
      <v-flex xs12 text-center mb-5>
        <h1>Brukeradministrasjon</h1>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex xs12>
        <v-card>
          <v-toolbar class="secondary" dark>
            <v-toolbar-title>Brukere</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon>
              <v-icon>person_add</v-icon>
            </v-btn>
          </v-toolbar>
          <v-progress-linear v-if="!users" indeterminate></v-progress-linear>
          <v-list two-line class="pb-0">
            <v-list-item
              v-for="user in users"
              :key="user.id"
              :class="user.active ? '' : 'inactive-user'"
            >
              <v-list-item-content>
                <v-list-item-title>{{ user.first_name }} {{ user.last_name }}</v-list-item-title>
                <v-list-item-subtitle>
                  <ul>
                    <li>
                      <span class="hidden-sm-and-down">Epost:</span>
                      <a :href="`mailto:${user.email}`">{{ user.email }}</a>
                    </li>
                    <li>
                      <span class="hidden-sm-and-down">Telefon:</span>
                      <a :href="`tel:${user.phone}`">{{ user.phone }}</a>
                    </li>
                    <li class="hidden-xs-only">
                      <span>Aktiv:</span>
                      {{ user.active ? 'Ja' : 'Nei' }}
                    </li>
                    <li>
                      <span>Rettigheter:</span>
                      <span
                        v-for="permission in user.permissions"
                        :key="permission.id"
                        class="permission"
                        >{{ permission.name }}</span
                      >
                    </li>
                  </ul>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon @click="editUser(user)">
                  <v-icon>edit</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>

          <v-alert
            v-model="generalErrorAlert"
            dismissible
            type="error"
            transition="slide-y-transition"
            class="mb-0"
            @input="generalErrorMsg = ''"
            >{{ generalErrorMsg }}</v-alert
          >
        </v-card>
      </v-flex>
    </v-layout>

    <v-dialog
      v-model="userDetailsDialog"
      width="400"
      persistent
      @keydown.esc="cancelEditingUser"
      @keydown.ctrl.enter="saveEditedUser"
    >
      <v-card v-if="selectedUser">
        <v-card-title class="headline primary white--text">Rediger bruker</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="selectedUser.first_name"
            clearable
            label="Fornavn"
            :rules="[$validationRules.required]"
          ></v-text-field>
          <v-text-field
            v-model="selectedUser.last_name"
            clearable
            label="Etternavn"
            :rules="[$validationRules.required]"
          ></v-text-field>
          <v-text-field
            v-model="selectedUser.email"
            clearable
            label="Epost"
            :rules="[$validationRules.required, $validationRules.email]"
          ></v-text-field>
          <v-text-field
            v-model="selectedUser.phone"
            clearable
            label="Telefon"
            :rules="[$validationRules.required]"
          ></v-text-field>
          <v-switch
            v-model="selectedUser.active"
            :label="selectedUser.active ? 'Aktiv' : 'Inaktiv'"
            color="success"
          ></v-switch>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="saveEditedUser">Lagre</v-btn>
          <v-btn text color="primary" @click="cancelEditingUser">Avbryt</v-btn>
        </v-card-actions>
      </v-card>

      <v-alert
        v-model="editUserErrorAlert"
        dismissible
        type="error"
        transition="slide-y-transition"
        class="mb-0"
        @input="editUserErrorMsg = ''"
        >{{ editUserErrorMsg }}</v-alert
      >
    </v-dialog>
  </v-container>
</template>

<script>
import validationRules from '@/mixins/validationRules'
import User from '@/models/User'

export default {
  mixins: [validationRules],
  data: () => {
    return {
      selectedUser: null,
      userDetailsDialog: false,
      generalErrorAlert: false,
      generalErrorMsg: '',
      editUserErrorAlert: false,
      editUserErrorMsg: '',
      rules: {
        required: value => !!value || 'Påkrevd',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Ugyldig epostadresse'
        },
      },
    }
  },
  computed: {
    users() {
      return User.query()
        .with('permissions')
        .orderBy('active', 'desc')
        .get()
    },
  },

  methods: {
    cancelEditingUser() {
      this.selectedUser = null
      this.userDetailsDialog = false
    },
    editUser(user) {
      this.selectedUser = Object.assign(new User(), user)
      this.userDetailsDialog = true
    },
    saveEditedUser() {
      User.updateOne(this.selectedUser.id, this.selectedUser)
      this.userDetailsDialog = false
    },
  },
}
</script>

<style scoped>
ul {
  padding-left: 0;
}

li {
  display: inline-block;
  margin-right: 1em;
}

.inactive-user {
  background-color: #eee;
}

.permission {
  padding-left: 0.5em;
}
</style>
