import BaseModel from './BaseModel'

export default class Our_contactinfo extends BaseModel {
  static entity = 'our_contactinfos'
  static primaryKey = 'our_contactinfo_id'

  static fields() {
    return {
      our_contactinfo_id: this.attr(null),
      text: this.string(),
      applied_from: this.attr(null),
      applied_to: this.attr(null),
    }
  }
}
