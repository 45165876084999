import BaseModel from './BaseModel'

export default class Phonenumber extends BaseModel {
  static entity = 'phonenumbers'
  static dataField = 'phonenumber'
  static primaryKey = 'phonenumber_id'

  static fields() {
    return {
      phonenumber_id: this.attr(null),
      phonenumber: this.string(),
    }
  }
}
