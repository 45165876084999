import BaseModel from './BaseModel'

export default class Webacceptance extends BaseModel {
  static entity = 'webacceptances'
  static primaryKey = 'acceptance_id'

  static fields() {
    return {
      acceptance_id: this.attr(),
      acceptancetype_id: this.attr(),
      ipaddress: this.string(),
      text: this.string(),
    }
  }
}
