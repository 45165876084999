import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VApp,[_c('MainNav'),_c(VMain,[_c(VContainer,[_c('router-view',{on:{"toast":_vm.toast}}),_c(VSnackbar,_vm._b({scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c(VBtn,_vm._b({class:_vm.snackbar.props ? _vm.snackbar.props.contentClass : '',attrs:{"icon":""},on:{"click":function($event){_vm.snackbar.show = false}}},'v-btn',attrs,false),[_c(VIcon,[_vm._v("close")])],1)]}}]),model:{value:(_vm.snackbar.show),callback:function ($$v) {_vm.$set(_vm.snackbar, "show", $$v)},expression:"snackbar.show"}},'v-snackbar',_vm.snackbar.props,false),[_vm._v(" "+_vm._s(_vm.snackbar.text)+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }