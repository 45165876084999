import Color from 'color'
export default {
  methods: {
    isDark(color) {
      return Color(color).isDark()
    },
    isLight(color) {
      return Color(color).isLight()
    },
  },
}
