import BaseModel from './BaseModel'

export default class Acceptancetype extends BaseModel {
  static entity = 'acceptancetypes'
  static primaryKey = 'acceptancetype_id'

  static fields() {
    return {
      acceptancetype_id: this.attr(null),
      name: this.string(),
    }
  }
}
