<template>
  <EntityDialog ref="entityDialog" v-bind="companyUserDialog" @setNewEntity="handleEntityCreated" />
</template>

<script>
import EntityDialog from '@/components/dialogs/EntityDialog.vue'
import validationRules from '@/mixins/validationRules.js'
import Company from '@/models/Company.js'
import CompanyUser from '@/models/CompanyUser.js'
import User from '@/models/User.js'

export default {
  name: 'CompanyUserDialog',
  components: {
    EntityDialog,
  },
  mixins: [validationRules],
  data: () => {
    return {
      loading: false,
      selectedCompanyUser: null,
    }
  },
  computed: {
    users() {
      let existingCompanyUserIds = this.selectedCompanyUser?.company?.users.map(user => {
        return user.user_id
      })

      existingCompanyUserIds ??= []

      return User.query()
        .where(user => {
          return !existingCompanyUserIds.includes(user.user_id)
        })
        .get()
    },
    companyUserDialog() {
      return {
        createTitle: 'Tilknytt ny selger',
        fields: [
          {
            type: 'lookupAutocomplete',
            model: Company,
            name: 'company_id',
            label: 'Bedrift',
            item: this.selectedCompanyUser?.company,
            itemValue: 'company_id',
            disabled: this.selectedCompanyUser?.company != null,
            itemText: 'name',
            rules: [this.$validationRules.required],
          },
          {
            type: 'select',
            model: User,
            label: 'Selger',
            name: 'user_id',
            items: this.users,
            itemText: 'full_name',
            itemValue: 'user_id',
            disabled: this.selectedCompanyUser?.user != null,
            rules: [this.$validationRules.required],
          },
        ],
      }
    },
  },
  methods: {
    handleEntityCreated(instance) {
      const companyUser = CompanyUser.query()
        .whereId([instance.company_id, instance.user_id])
        .with('company')
        .with('user')
        .first()

      this.$refs.entityDialog.entity = companyUser
      this.selectedCompanyUser = companyUser

      this.$parent.$successToast(
        `Du knyttet ${companyUser.user.full_name} til ${companyUser.company.name}`
      )

      this.closeDialog()
    },
    handleError(message) {
      this.$refs.entityDialog.showErrorAlert(message)
      this.loading = false
    },
    showDialog(companyUser) {
      this.selectedCompanyUser = companyUser
      this.$refs.entityDialog.showDialog(CompanyUser, companyUser)
    },
    closeDialog() {
      this.$refs.entityDialog.closeDialog()
      this.$emit('closeAndReset')
    },
  },
}
</script>
