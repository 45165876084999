<template>
  <v-dialog v-if="agreement" value="show" persistent width="750" hide-overlay>
    <v-card>
      <v-card-title class="headline primary white--text">
        {{ agreement.agreement_id ? 'Rediger produkt' : 'Opprett nytt produkt' }}<v-spacer />
        <v-btn v-if="!loading" icon color="white" @click="$emit('closeDialog')">
          <v-icon>close</v-icon>
        </v-btn>
        <v-progress-circular v-else indeterminate color="white" size="36"></v-progress-circular>
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-select
            v-model="agreement.agreementtype_id"
            :items="agreementtypes"
            item-value="agreementtype_id"
            label="Type produkt"
            @change="handleAgreementtype"
          >
            <template v-slot:selection="agreementtype">{{ agreementtype.item.name }}</template>
            <template v-slot:item="agreementtype">{{ agreementtype.item.name }}</template>
          </v-select>

          <v-text-field
            v-model="agreement.name"
            label="Tittel"
            clearable
            :rules="[$validationRules.required]"
            @change="handleName"
          ></v-text-field>

          <v-textarea
            v-model="agreement.description"
            label="Beskrivelse"
            auto-grow
            clearable
            @blur="handleDescription"
          />

          <v-text-field
            v-model="agreement.full_price"
            label="Full pris"
            type="number"
            clearable
            :rules="[$validationRules.required]"
            @blur="handleFullPrice"
          ></v-text-field>

          <v-slider
            v-model="agreement.discount_percentage"
            label="Rabatt (%)"
            class="my-4"
            step="5"
            ticks="always"
            thumb-label="always"
            :thumb-size="32"
            @end="handleDiscount"
          />

          <v-slider
            v-model="agreement.duration_months"
            label="Avtaleperiode (mnd)"
            max="24"
            step="0.5"
            ticks="always"
            thumb-label="always"
            :thumb-size="32"
            @end="handleDuration"
          />

          <span v-if="agreement.discount_percentage">
            Rabattert pris:
            {{ getDiscountedPrice(agreement.full_price, agreement.discount_percentage) | currency }}
          </span>

          <v-checkbox
            v-model="agreement.no_autorenewal"
            :disabled="agreement.duration_months < 6"
            label="Ingen automatisk fornyelse selv om avtaleperioden er 6mnd+"
            @change="handleAutorenewal"
          ></v-checkbox>
        </v-form>

        <ErrorAlert v-bind="errorAlert" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import amounts from '@/mixins/amounts'
import entityPersistence from '@/mixins/entityPersistence'
import errorAlert from '@/mixins/errorAlert'
import validationRules from '@/mixins/validationRules'
import Agreement from '@/models/Agreement'
import Agreementtype from '@/models/Agreementtype'
import ErrorAlert from './ErrorAlert'

export default {
  name: 'AgreementDialog',
  components: {
    ErrorAlert,
  },
  mixins: [amounts, entityPersistence, errorAlert, validationRules],
  props: {
    show: Boolean,
    agreement: {
      type: Agreement,
      default: null,
    },
  },
  data: () => {
    return {
      loading: false,
      errorAlert: {
        show: false,
        errors: [],
      },
    }
  },
  computed: {
    agreementtypes() {
      return Agreementtype.query().get()
    },
  },

  methods: {
    async handleAgreementtype() {
      console.log('agreement.agreementtype handler')
      await this.createOrUpdate({ agreementtype_id: this.agreement.agreementtype_id })
    },

    async handleName() {
      console.log('agreement.name handler')
      await this.createOrUpdate({ name: this.agreement.name })
    },

    async handleDescription() {
      console.log('agreement.description handler')
      if (this.agreement.description && !this.agreement.name) {
        //use the first line of the description as name if none is set
        const [name, ...description] = this.agreement.description.split('\n')
        this.agreement.name = name.trim()
        this.agreement.description = description.join('\n').trim()
      }

      await this.createOrUpdate({ description: this.agreement.description })
    },

    async handleFullPrice() {
      console.log('agreement.full_price handler')
      await this.createOrUpdate({ full_price: this.agreement.full_price })
    },

    async handleDiscount() {
      console.log('agreement.discount_percentage handler')
      await this.createOrUpdate({ discount_percentage: this.agreement.discount_percentage })
    },

    async handleDuration() {
      console.log('agreement.duration_months handler')
      await this.createOrUpdate({ duration_months: this.agreement.duration_months })
    },

    async handleAutorenewal() {
      console.log('agreement.no_autorenewal handler')
      await this.createOrUpdate({ no_autorenewal: this.agreement.no_autorenewal })
    },

    async createOrUpdate(data) {
      if (!this.$refs.form.validate()) {
        return
      }

      this.clearErrorAlert()
      if (!this.agreement.agreement_id) {
        data = {
          contract_id: this.agreement.contract_id,
          agreementtype_id: this.agreement.agreementtype_id,
          name: this.agreement.name,
          description: this.agreement.description,
          full_price: this.agreement.full_price,
        }
      }
      await this.createOrUpdateEntity(Agreement, data, this.agreement.agreement_id)
    },

    handleError(message) {
      this.showErrorAlert(message)
      this.loading = false
    },
  },
}
</script>

<style>
.v-input--checkbox:not(.v-input--is-disabled) label {
  color: rgba(0, 0, 0, 0.87) !important;
}
</style>
