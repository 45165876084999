import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import Companies from './views/Companies.vue'
import Company from './views/Company.vue'
import Callback from './components/Callback'
import auth from './auth/authentication'
import Profile from './views/Profile.vue'
import UserManagement from './views/UserManagement.vue'
import Contactpersons from './views/Contactpersons.vue'
import Contracts from './views/Contracts.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/callback', //callback url for auth0 token (re-)validation
      name: 'callback',
      component: Callback,
    },
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/companies',
      name: 'companies',
      component: Companies,
    },
    {
      path: '/companies/:company_id',
      name: 'company',
      component: Company,
    },
    {
      path: '/profile',
      name: 'profile',
      component: Profile,
    },
    {
      path: '/users',
      name: 'users',
      component: UserManagement,
      beforeEnter: (to, from, next) => {
        next()
      },
    },
    {
      path: '/contactpersons',
      name: 'contactpersons',
      component: Contactpersons,
    },
    {
      path: '/contracts',
      name: 'contracts',
      component: Contracts,
    },
  ],
})

//redirect all url's except home and login callback page for users that are not logged in
router.beforeEach((to, _from, next) => {
  if (to.path === '/' || to.path === '/callback' || auth.isAuthenticated()) {
    return next()
  }
  auth.login({ target: to.path })
})

export default router
