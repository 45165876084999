import BaseModel from './BaseModel'
import Contract from './Contract'
import Acceptancetype from './Acceptancetype'

export default class Acceptance extends BaseModel {
  static entity = 'acceptances'
  static primaryKey = 'acceptance_id'

  static fields() {
    return {
      acceptance_id: this.attr(null),
      contract_id: this.attr(),
      contract: this.belongsTo(Contract, 'contract_id'),
      acceptancetype_id: this.attr(),
      acceptancetype: this.belongsTo(Acceptancetype, 'acceptancetype_id'),
      timestamp: this.string(),
    }
  }
}
