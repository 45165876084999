import BaseModel from './BaseModel'
import User from './User'
import Company from './Company'
import Contactperson from './Contactperson'
import Agreement from './Agreement'
import Acceptance from './Acceptance'
import Our_contactinfo from './Our_contactinfo'

export default class Contract extends BaseModel {
  static entity = 'contracts'
  static primaryKey = 'contract_id'

  static fields() {
    return {
      contract_id: this.attr(null),
      user_id: this.attr(),
      user: this.belongsTo(User, 'user_id'),
      company_id: this.attr(),
      company: this.belongsTo(Company, 'company_id'),
      contactperson_id: this.attr(),
      contactperson: this.belongsTo(Contactperson, 'contactperson_id'),
      note: this.string().nullable(),
      contact_name: this.string().nullable(),
      contact_email: this.string(),
      contact_phone: this.string(),
      billing_address: this.string(),
      signed_contract_url: this.string().nullable(),
      agreements: this.hasMany(Agreement, 'contract_id'),
      acceptance: this.hasOne(Acceptance, 'contract_id'),
      our_contactinfo_id: this.attr(),
      our_contactinfo: this.belongsTo(Our_contactinfo, 'our_contactinfo_id'),
    }
  }
}
