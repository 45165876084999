import { getFirstReturnedInstance } from '@/mixins/responseHelper'

export default {
  methods: {
    getAddedEntities(newEntities, oldEntities) {
      return newEntities.filter(({ $id: id1 }) => !oldEntities.some(({ $id: id2 }) => id2 === id1))
    },

    getRemovedEntities(newEntities, oldEntities) {
      return oldEntities.filter(({ $id: id1 }) => !newEntities.some(({ $id: id2 }) => id2 === id1))
    },

    async createAddedAdvancedRelatedEntities(newEntities, oldEntities, model, pivotModel) {
      if (!newEntities || !oldEntities) {
        return
      }

      const addedEntities = this.getAddedEntities(newEntities, oldEntities)

      addedEntities.forEach(async addedEntity => {
        this.loading = true
        try {
          const pivotData = { ...this.entity.primaryKey.key, ...addedEntity.primaryKey.key }
          addedEntity.pivot = await pivotModel.createOne(pivotData)
        } catch (e) {
          this.handleError(e.message)
        } finally {
          this.loading = false
        }
      })
    },

    async createAddedSimpleRelatedEntities(newEntities, oldEntities, model, pivotModel) {
      if (!newEntities || !oldEntities) {
        return
      }

      this.getAddedEntities(newEntities, oldEntities).forEach(async addedEntity => {
        try {
          this.loading = true

          //check if the entity already exists
          const search = {}
          search[model.dataField] = {
            compare: 'exact',
            value: addedEntity[model.dataField],
          }

          const response = await model.fetchAll({ search })
          let relatedEntity = getFirstReturnedInstance(response)

          if (!relatedEntity) {
            relatedEntity = await model.createOne(addedEntity)
          }

          relatedEntity.pivot = await pivotModel.createOne({
            ...relatedEntity.primaryKey.key,
            ...this.entity.primaryKey.key,
          })

          //replace the "text only" entity with the properly created one
          const index = this.entity[model.entity].indexOf(addedEntity)
          this.entity[model.entity][index] = relatedEntity
        } catch (e) {
          this.handleError(`${e.message} - endringen ble ikke lagret`)
        } finally {
          this.loading = false
        }
      })
    },

    async removeRelatedEntities(newEntities, oldEntities, deleteRelatedEntity = false) {
      if (!newEntities || !oldEntities) {
        return
      }

      this.getRemovedEntities(newEntities, oldEntities).forEach(async removedEntity => {
        this.loading = true
        try {
          await removedEntity.pivot.delete()

          if (deleteRelatedEntity) {
            await removedEntity.delete()
          }
        } catch (e) {
          if (e.message == 'ER_ROW_IS_REFERENCED_2') {
            //the entity is used elsewhere, do nothing
          } else {
            this.handleError([`Det oppsto en feil: ${e.message}`, 'Endringen ble ikke lagret.'])
          }
        } finally {
          this.loading = false
        }
      })
    },

    async createOrUpdateEntity(model, data, id) {
      if (this.loading) {
        return
      }

      data = this.removeUndefinedPropertiesAndNullEmptyStrings(data)
      if (!data) return

      try {
        this.loading = true
        if (!id) {
          const createdEntity = await model.createOne(data)
          this.$emit('setNewEntity', createdEntity)
        } else {
          await model.updateOne(id, data)
        }
      } catch (e) {
        this.handleError([`Det oppsto en feil: ${e.message}`, 'Endringen ble ikke lagret.'])
      } finally {
        this.loading = false
      }
    },
    removeUndefinedPropertiesAndNullEmptyStrings(obj) {
      const copy = { ...obj }
      for (const key in copy) {
        if (copy[key] === undefined) {
          delete copy[key]
          continue
        }

        if (copy[key] === '') {
          copy[key] = null
        }
      }
      return copy
    },
  },
}
