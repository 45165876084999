import BaseModel from '@/models/BaseModel'
import Company from '@/models/Company'
import User from '@/models/User'

export default class CompanyUser extends BaseModel {
  static entity = 'companies_users'

  static primaryKey = ['company_id', 'user_id']

  static fields() {
    return {
      company_id: this.attr(null),
      user_id: this.attr(null),
      is_main_salesperson: this.boolean(false),
      company: this.belongsTo(Company, 'company_id'),
      user: this.belongsTo(User, 'user_id'),
    }
  }
}
