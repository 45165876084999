import BaseModel from './BaseModel'

export default class UserPermission extends BaseModel {
  static entity = 'users_permissions'

  static primaryKey = ['user_id', 'permission_id']

  static fields() {
    return {
      user_id: this.attr(null),
      permission_id: this.attr(null),
    }
  }
}
