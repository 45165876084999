export default {
  methods: {
    //check if an object has any string property that is longer than x characters
    hasStringLongerThan(object, threshold) {
      for (let value of Object.values(object)) {
        if (typeof value != 'string') {
          continue
        }

        if (value.length > threshold) {
          return true
        }
      }
      return false
    },
  },
}
