<template>
  <v-alert type="error" class="mt-8" transition="slide-y-reverse-transition" :value="show">
    <div v-for="(error, i) in errors" :key="i">{{ error }}</div>
  </v-alert>
</template>

<script>
export default {
  name: 'ErrorAlert',
  props: {
    show: Boolean,
    errors: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
}
</script>
