import BaseModel from './BaseModel'

export default class Emailaddress extends BaseModel {
  static entity = 'emailaddresses'
  static dataField = 'emailaddress'
  static primaryKey = 'emailaddress_id'

  static fields() {
    return {
      emailaddress_id: this.attr(null),
      emailaddress: this.string(),
    }
  }
}
