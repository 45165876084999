import BaseModel from './BaseModel'
import Phonenumber from './Phonenumber'
import contactpersonPhonenumber from './ContactpersonPhonenumber'
import Emailaddress from './Emailaddress'
import contactpersonEmailaddress from './ContactpersonEmailaddress'
import CompanyContactperson from './CompanyContactperson'
import Company from './Company'

export default class Contactperson extends BaseModel {
  static entity = 'contactpersons'
  static primaryKey = 'contactperson_id'

  static fields() {
    return {
      contactperson_id: this.attr(null),
      first_name: this.string(),
      last_name: this.string(),
      phonenumbers: this.belongsToMany(
        Phonenumber,
        contactpersonPhonenumber,
        'contactperson_id',
        'phonenumber_id'
      ),
      emailaddresses: this.belongsToMany(
        Emailaddress,
        contactpersonEmailaddress,
        'contactperson_id',
        'emailaddress_id'
      ),
      companies: this.belongsToMany(
        Company,
        CompanyContactperson,
        'contactperson_id',
        'company_id'
      ),
    }
  }
  get full_name() {
    return `${this.first_name} ${this.last_name}`
  }
  get firstEmailaddress() {
    return this.emailaddresses?.at(0)
  }
  get firstPhonenumber() {
    return this.phonenumbers?.at(0)
  }
}
