import BaseModel from './BaseModel'
import User from './User'
import Phonenumber from './Phonenumber'
import companyPhonenumber from './CompanyPhonenumber'
import Emailaddress from './Emailaddress'
import companyEmailaddress from './CompanyEmailaddress'
import Contactperson from './Contactperson'
import Contract from './Contract'
import companyContactperson from './CompanyContactperson'
import CompanyUser from './CompanyUser'
import Industry from './Industry'
import companyIndustry from './CompanyIndustry'
import Companycomment from './Companycomment'

export default class Company extends BaseModel {
  static entity = 'companies'
  static primaryKey = 'company_id'

  static fields() {
    return {
      company_id: this.attr(null),
      orgnr: this.string(),
      name: this.string(),
      billing_address: this.string(),
      dissolved: this.boolean(false),
      contactpersons: this.belongsToMany(
        Contactperson,
        companyContactperson,
        'company_id',
        'contactperson_id'
      ),
      phonenumbers: this.belongsToMany(
        Phonenumber,
        companyPhonenumber,
        'company_id',
        'phonenumber_id'
      ),
      emailaddresses: this.belongsToMany(
        Emailaddress,
        companyEmailaddress,
        'company_id',
        'emailaddress_id'
      ),
      industries: this.belongsToMany(Industry, companyIndustry, 'company_id', 'industry_id'),
      users: this.belongsToMany(User, CompanyUser, 'company_id', 'user_id'),
      contracts: this.hasMany(Contract, 'company_id'),
      companycomments: this.hasMany(Companycomment, 'company_id'),
    }
  }

  get firstContactperson() {
    return this.contactpersons?.at(0)
  }

  async setNewMainSalesperson(user) {
    if (!user?.user_id) return
    const url = `/companies/${this.company_id}/set_main_salesperson`
    await Company.api().put(url, { user_id: user.user_id })
    await CompanyUser.fetchByKey('company_id', this.company_id)
  }
}
