<template>
  <div>
    <v-data-table
      v-if="$currentUser"
      :headers="dataTableHeaders"
      :items="contracts"
      :loading="loading"
      item-key="id"
      class="contracts-table"
      loading-text="Henter data"
      disable-sort
      disable-pagination
    >
      <template v-slot:item="contracts">
        <tr>
          <td>
            <template v-if="contracts.item.acceptance">
              {{ contracts.item.acceptance.timestamp | moment('DD.MM.YYYY') }}
            </template>
            <span v-else class="font-italic grey--text">Ingen aksept</span>
          </td>
          <td>
            <template v-if="contracts.item.agreements.length > 0">
              <span v-for="agreement in contracts.item.agreements" :key="agreement.id">
                <v-chip v-if="agreement.agreementtype" class="mr-1">
                  {{ agreement.agreementtype.name }} {{ agreement.duration_months }}mnd -
                  {{
                    getDiscountedPrice(agreement.full_price, agreement.discount_percentage)
                      | currency
                  }}
                </v-chip>
              </span>
            </template>
            <span v-else class="font-italic grey--text">Ingen produkter</span>
          </td>
          <td>
            {{ getTotalAmount(contracts.item.agreements) | currency }}
            <span v-if="getTotalAmount(contracts.item.agreements) === 0" class="grey--text">-</span>
          </td>
          <td>
            <template v-if="contracts.item.user">
              {{ contracts.item.user.first_name }}
              {{ contracts.item.user.last_name }}
            </template>
          </td>
          <td>
            <v-btn
              v-if="contracts.item.signed_contract_url"
              icon
              color="primary"
              @click="$openLinkInNewWindow(contracts.item.signed_contract_url)"
              ><v-icon>zoom_in</v-icon></v-btn
            >
            <TooltipButton
              v-bind="editContractButton"
              @click="showEditContractSheet(contracts.item)"
            />
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-bottom-sheet v-model="editContractSheet.show" inset persistent max-width="1000px">
      <EditContractSheet
        v-bind="editContractSheet"
        :disabled-form-fields="['company']"
        @closeAndReset="closeAndResetEditContractSheet"
        @deletedContract="closeAndResetEditContractSheet"
        @acceptanceChanged="setNewContract"
    /></v-bottom-sheet>
  </div>
</template>

<script>
import amounts from '@/mixins/amounts'
import currentUser from '@/mixins/currentUser.js'
import editContractSheet from '@/mixins/editContractSheet'
import TooltipButton from '@/components/buttons/TooltipButton'
import EditContractSheet from '@/components/EditContractSheet'

export default {
  components: { EditContractSheet, TooltipButton },
  mixins: [amounts, currentUser, editContractSheet],
  props: {
    contracts: {
      type: Array,
      default: () => [],
    },
  },
  data: () => {
    return {
      loading: false,
      generalErrorMsg: '',
      generalErrorAlert: false,
      dataTableHeaders: [
        { text: 'Kontraktsdato', value: 'signed' },
        { text: 'Produkter', value: 'products' },
        { text: 'Totalbeløp', value: 'amount' },
        { text: 'Selger', value: 'user' },
        {},
      ],
    }
  },
  computed: {},
  methods: {},
}
</script>

<style lang="less" scoped>
@media (max-width: 600px) {
  .contracts-table {
    table,
    tbody,
    tr,
    td {
      display: block !important;
    }

    td {
      vertical-align: top;
      padding-inline: 0 !important;

      &:before {
        display: block;
        font-weight: bold;
        margin-top: 1rem;
      }

      &:nth-child(1):before {
        content: 'Kontraktsdato:';
      }
      &:nth-child(2):before {
        content: 'Avtaler:';
      }
      &:nth-child(3):before {
        content: 'Totalbeløp:';
      }
      &:nth-child(4):before {
        content: 'Selger:';
      }
    }
  }
}
</style>
