import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarItems } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',[_c(VToolbar,{attrs:{"color":"primary","dark":""}},[_c(VToolbarTitle,{staticClass:"headline"},[_c('span',[_vm._v("agree")]),_c('span',{staticClass:"font-weight-light"},[_vm._v(".ly")])]),_c(VSpacer),(_vm.isAuthenticated)?_c(VToolbarItems,[_vm._l((_vm.links),function(link){return _c(VBtn,{key:link.text,attrs:{"to":{ name: link.route },"text":"","exact":""}},[_vm._v(_vm._s(link.text))])}),_c(VBtn,{attrs:{"text":""},on:{"click":_vm.logout}},[_c('span',{staticClass:"mr-2"},[_vm._v("Logg ut")]),_c(VIcon,[_vm._v("exit_to_app")])],1)],2):_vm._e(),(!_vm.isAuthenticated)?_c(VToolbarItems,[_c(VBtn,{attrs:{"text":""},on:{"click":_vm.login}},[_c('span',{staticClass:"mr-2"},[_vm._v("Logg inn")]),_c(VIcon,[_vm._v("exit_to_app")])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }