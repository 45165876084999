import Contract from '@/models/Contract'

export default {
  data: () => {
    return {
      editContractSheet: {
        show: false,
        contract: null,
      },
    }
  },
  computed: {
    createContractButton() {
      return {
        button: {
          icon: 'add',
          disabled: !this.$currentUser.hasPermission('CONTRACT_CREATE') || this.loading,
          color: 'white',
          dark: true,
        },
        tooltip: {
          openDelay: 500,
          bottom: true,
          text: this.$currentUser.hasPermission('CONTRACT_CREATE')
            ? 'Opprett kontrakt'
            : 'Du mangler tillatelse til å opprette kontrakter',
        },
      }
    },
    editContractButton() {
      return {
        button: {
          icon: 'edit',
          disabled: !this.$currentUser.hasPermission('CONTRACT_UPDATE') || this.loading,
          color: 'primary',
          dark: true,
        },
        tooltip: {
          openDelay: 500,
          bottom: true,
          text: this.$currentUser.hasPermission('CONTRACT_UPDATE')
            ? 'Rediger kontrakt'
            : 'Du mangler tillatelse til å redigere kontrakter',
        },
      }
    },
  },
  methods: {
    showEditContractSheet(contract = { user_id: this.$currentUser.user_id }) {
      if (!(contract instanceof Contract)) {
        contract = new Contract(contract)
      }

      this.editContractSheet.contract = contract
      this.editContractSheet.show = true
    },
    closeAndResetEditContractSheet() {
      this.editContractSheet.show = false
      this.editContractSheet.contract = null
    },
    setNewContract(contract) {
      this.editContractSheet.contract = Contract.query()
        .whereId(contract.contract_id)
        .withAll()
        .with('company.contactpersons')
        .with('company.contactpersons.phonenumbers')
        .with('company.contactpersons.emailaddresses')
        .with('company.emailaddresses')
        .with('contactperson.phonenumbers')
        .with('contactperson.emailaddresses')
        .first()
    },
  },
}
