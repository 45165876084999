import Contactperson from '@/models/Contactperson'

export default {
  data: () => {
    return {
      contactpersonDialog: {
        show: false,
        contactperson: null,
      },
    }
  },
  methods: {
    async showContactpersonDialog(params = {}) {
      if (params.contactperson) {
        this.contactpersonDialog.contactperson = params.contactperson
      } else {
        this.contactpersonDialog.contactperson = new Contactperson(params.fields)
      }
      this.contactpersonDialog.show = true
    },
    closeAndResetContactpersonDialog() {
      this.contactpersonDialog.show = false
      this.contactpersonDialog.contactperson = null
    },
  },
}
