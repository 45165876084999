import Company from '@/models/Company'

export default {
  data: () => {
    return {
      companyDialog: {
        show: false,
        company: null,
      },
    }
  },
  methods: {
    //TODO replace with currently logged in user id
    async showCompanyDialog(params = { fields: { user_id: 1 } }) {
      if (params.company) {
        this.companyDialog.company = params.company
      } else {
        this.companyDialog.company = new Company(params.fields)
      }
      this.companyDialog.show = true
    },
    closeAndResetCompanyDialog() {
      this.companyDialog.show = false
      this.companyDialog.company = null
    },
  },
}
