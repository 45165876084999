import moment from 'moment'

export default {
  methods: {
    getCurrentDate() {
      return moment().format('YYYY-MM-DD')
    },
    getCurrentTime() {
      return moment().format('HH:mm')
    },
    concatDateAndTime(dateAndTime) {
      if (!dateAndTime.date && !dateAndTime.time) return
      return moment(`${dateAndTime.date} ${dateAndTime.time}`).format('YYYY-MM-DD HH:mm:ss')
    },
    isBeforeCurrentTime(dateAndTime) {
      if (dateAndTime?.date && dateAndTime?.time) {
        return moment(this.concatDateAndTime(dateAndTime)).isBefore()
      }
      return moment(dateAndTime).isBefore()
    },
    formatDate(date) {
      return moment(date).format('MM.DD.YYYY')
    },
  },
}

export const splitDatetime = datetime => {
  if (!datetime) return

  const split = {}
  ;[split.date, split.time] = datetime.split(' ')
  return split
}
