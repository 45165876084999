import BaseModel from './BaseModel'

export default class Autoacceptance extends BaseModel {
  static entity = 'autoacceptances'
  static primaryKey = 'acceptance_id'

  static fields() {
    return {
      acceptance_id: this.attr(),
      acceptancetype_id: this.attr(),
      signee_name: this.string(),
      description: this.string(),
    }
  }
}
