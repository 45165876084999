export default {
  computed: {
    createCompanyUserButton() {
      return {
        button: {
          icon: 'add',
          disabled:
            (this.$currentUser && !this.$currentUser.hasPermission('COMPANY_USER_CREATE')) ||
            this.loading,
          color: 'white',
        },
        tooltip: {
          openDelay: 500,
          bottom: true,
          text:
            this.$currentUser && this.$currentUser.hasPermission('COMPANY_USER_CREATE')
              ? 'Knytt selger til bedrift'
              : 'Du mangler tillatelse til å knytte selgere til bedrifter',
        },
      }
    },
  },
}
