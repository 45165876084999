<template>
  <nav>
    <v-toolbar color="primary" dark>
      <v-toolbar-title class="headline">
        <span>agree</span>
        <span class="font-weight-light">.ly</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items v-if="isAuthenticated">
        <v-btn v-for="link in links" :key="link.text" :to="{ name: link.route }" text exact>{{
          link.text
        }}</v-btn>
        <v-btn text @click="logout">
          <span class="mr-2">Logg ut</span>
          <v-icon>exit_to_app</v-icon>
        </v-btn>
      </v-toolbar-items>
      <v-toolbar-items v-if="!isAuthenticated">
        <v-btn text @click="login">
          <span class="mr-2">Logg inn</span>
          <v-icon>exit_to_app</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      links: [
        { route: 'home', text: 'Hjem' },
        { route: 'companies', text: 'Bedrifter' },
        { route: 'contactpersons', text: 'Kontaktpersoner' },
        { route: 'contracts', text: 'Kontrakter' },
        { route: 'users', text: 'Brukere' },
        { route: 'profile', text: 'Profil' },
      ],
      isAuthenticated: false,
    }
  },
  methods: {
    login() {
      this.$auth.login()
    },
    logout() {
      this.$auth.logOut()
    },
    handleLoginEvent(data) {
      this.isAuthenticated = data.loggedIn
    },
  },
}
</script>
