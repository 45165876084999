<template>
  <v-dialog v-if="company" value="show" persistent max-width="800px" @keydown.esc="closeDialog">
    <v-card>
      <v-card-title class="headline primary white--text"
        >{{ company.company_id ? 'Rediger bedrift' : 'Opprett ny bedrift' }}
        <v-spacer></v-spacer>
        <v-btn
          v-if="!loading"
          icon
          color="white"
          :disabled="contactpersonDialog.show"
          @click="closeDialog"
        >
          <v-icon>close</v-icon>
        </v-btn>
        <v-progress-circular v-else indeterminate color="white" size="36"></v-progress-circular>
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-text-field
            v-model="company.name"
            label="Navn"
            clearable
            :rules="[$validationRules.required]"
            :disabled="loading"
            @blur="handleNameField"
            @keyup.enter="handleNameField"
          />

          <v-text-field
            v-model="company.orgnr"
            label="Org.nr"
            clearable
            :rules="[$validationRules.required]"
            :disabled="loading"
            @blur="handleOrgnrField"
            @keyup.enter="handleOrgnrField"
          />

          <v-text-field
            v-model="company.billing_address"
            label="Fakturaadresse"
            clearable
            :rules="[$validationRules.required]"
            :disabled="loading"
            @blur="handleBillingaddressField"
            @keyup.enter="handleBillingaddressField"
          />

          <v-select
            v-model="company.user_id"
            :items="users"
            item-value="user_id"
            label="Hovedselger"
            :disabled="loading"
            @change="handleUserField"
          >
            <template v-slot:selection="user">{{ user.item.first_name }}</template>
            <template v-slot:item="user">{{ user.item.first_name }}</template>
          </v-select>

          <v-combobox
            ref="industries"
            v-model="company.industries"
            :items="industries"
            item-text="name"
            label="Bransjer"
            multiple
            small-chips
            deletable-chips
            hide-selected
            return-object
            :disabled="!company.company_id || loading"
          />

          <v-combobox
            v-model="company.phonenumbers"
            label="Telefon"
            item-text="phonenumber"
            multiple
            small-chips
            deletable-chips
            return-object
            :disabled="!company.company_id || loading"
          />

          <v-combobox
            v-model="company.emailaddresses"
            label="E-post"
            item-text="emailaddress"
            multiple
            small-chips
            deletable-chips
            return-object
            :disabled="!company.company_id || loading"
            @input="handleEmailaddressInput"
          />

          <v-autocomplete
            ref="contactpersons"
            v-model="company.contactpersons"
            :items="contactpersons"
            label="Kontaktpersoner"
            multiple
            chips
            deletable-chips
            item-text="full_name"
            no-data-text="Fant ingen kontaktpersoner som matcher søket"
            return-object
            :search-input.sync="search.contactpersons"
            cache-items
            :disabled="!company.company_id || loading"
            @change="handleContactpersonSelected"
          >
            <template v-slot:item="contactperson">{{ contactperson.item.full_name }}</template>

            <template v-slot:append-item>
              <v-btn
                color="primary"
                text
                small
                class="mt-2 ml-2"
                :disabled="search.loading"
                @click="
                  showContactpersonDialog({
                    fields: {
                      companies: [company],
                    },
                  })
                "
              >
                <v-icon>add</v-icon>Opprett ny kontaktperson
              </v-btn>
              <v-progress-circular v-if="search.loading" indeterminate size="24" class="ml-2">
              </v-progress-circular>
            </template>
          </v-autocomplete>
        </v-form>
        <ErrorAlert v-bind="errorAlert" />
      </v-card-text>
    </v-card>

    <ContactpersonDialog
      :show="contactpersonDialog.show"
      :contactperson="contactpersonDialog.contactperson"
      @closeAndReset="closeAndResetContactpersonDialog"
      @setNewEntity="setNewContactperson"
    />
  </v-dialog>
</template>

<script>
import debounce from 'debounce'
import contactpersonDialog from '@/mixins/contactpersonDialog'
import entityPersistence from '@/mixins/entityPersistence.js'
import errorAlert from '@/mixins/errorAlert.js'
import responseHelper from '@/mixins/responseHelper'
import validationRules from '@/mixins/validationRules.js'
import ContactpersonDialog from '@/components/ContactpersonDialog'
import ErrorAlert from '@/components/ErrorAlert'
import Company from '@/models/Company'
import CompanyIndustry from '@/models/CompanyIndustry'
import CompanyContactperson from '@/models/CompanyContactperson'
import CompanyEmailaddress from '@/models/CompanyEmailaddress'
import CompanyPhonenumber from '@/models/CompanyPhonenumber'
import Contactperson from '@/models/Contactperson'
import Emailaddress from '@/models/Emailaddress'
import Industry from '@/models/Industry'
import Phonenumber from '@/models/Phonenumber'
import User from '@/models/User'

export default {
  name: 'CompanyDialog',
  components: {
    ErrorAlert,
    ContactpersonDialog,
  },
  mixins: [contactpersonDialog, entityPersistence, errorAlert, validationRules, responseHelper],
  props: {
    show: Boolean,
    company: {
      type: Company,
      default: null,
    },
  },
  data: () => {
    return {
      search: {
        contactpersons: null,
        loading: false,
      },
      loading: false,
      contactpersons: [],
      errorAlert: {
        show: false,
        errors: [],
      },
    }
  },
  computed: {
    entity() {
      return this.company
    },
    users() {
      return [{ first_name: 'Ingen', user_id: null }, ...User.query().get()]
    },
    industries() {
      return Industry.query().get()
    },
  },
  watch: {
    show() {
      if (this.show && this.company) {
        this.contactpersons = this.company.contactpersons
      }
    },

    'company.industries'(newEntities, oldEntities) {
      if (!newEntities || !oldEntities) {
        return
      }

      this.clearErrorAlert()
      this.createAddedSimpleRelatedEntities(newEntities, oldEntities, Industry, CompanyIndustry)
      this.removeRelatedEntities(newEntities, oldEntities, true)
      this.$refs.industries.isMenuActive = false
    },

    'company.phonenumbers'(newEntities, oldEntities) {
      this.clearErrorAlert()
      this.createAddedSimpleRelatedEntities(
        newEntities,
        oldEntities,
        Phonenumber,
        CompanyPhonenumber
      )
      this.removeRelatedEntities(newEntities, oldEntities, true)
    },

    'company.emailaddresses'(newEntities, oldEntities) {
      this.clearErrorAlert()
      this.createAddedSimpleRelatedEntities(
        newEntities,
        oldEntities,
        Emailaddress,
        CompanyEmailaddress
      )
      this.removeRelatedEntities(newEntities, oldEntities, true)
    },

    'company.contactpersons'(newEntities, oldEntities) {
      this.clearErrorAlert()
      this.createAddedAdvancedRelatedEntities(
        newEntities,
        oldEntities,
        Contactperson,
        CompanyContactperson
      )
      this.removeRelatedEntities(newEntities, oldEntities)
      return
    },

    'search.contactpersons': {
      handler: debounce(async function(searchFor) {
        this.contactpersons = this.company.contactpersons
        this.clearErrorAlert()
        if (!searchFor || searchFor.length < 3) {
          return
        }

        const searchResult = await this.fetchContactpersons(searchFor)
        if (searchResult) {
          this.contactpersons = [...this.company.contactpersons, ...searchResult]
        }
      }, 250),
    },
  },
  methods: {
    async fetchContactpersons(searchFor) {
      if (!searchFor) {
        return
      }

      let result
      try {
        this.search.loading = true
        result = await Contactperson.fetchAll({ search: { full_name: searchFor } })
      } catch (e) {
        this.handleContactpersonSelected(null)
        this.handleError([e.message, 'Kunne ikke søke etter kontaktpersoner'])
      } finally {
        this.search.loading = false
      }
      return result.entities.contactpersons
    },

    async handleNameField() {
      if (!this.show || !this.$refs.form.validate()) {
        return
      }

      this.clearErrorAlert()

      if (this.company.company_id) {
        await this.createOrUpdateEntity(
          Company,
          { name: this.company.name },
          this.company.company_id
        )
      } else {
        await this.createEntity()
      }
    },

    async handleOrgnrField() {
      if (!this.show || !this.$refs.form.validate()) {
        return
      }

      this.clearErrorAlert()

      if (this.company.company_id) {
        await this.createOrUpdateEntity(
          Company,
          { orgnr: this.company.orgnr },
          this.company.company_id
        )
      } else {
        await this.createEntity()
      }
    },

    async handleBillingaddressField() {
      if (!this.show || !this.$refs.form.validate()) {
        return
      }

      this.clearErrorAlert()

      if (this.company.company_id) {
        await this.createOrUpdateEntity(
          Company,
          { billing_address: this.company.billing_address },
          this.company.company_id
        )
      } else {
        await this.createEntity()
      }
    },

    async handleUserField() {
      if (!this.show || !this.$refs.form.validate() || !this.company.company_id) {
        return
      }

      this.clearErrorAlert()

      await this.createOrUpdateEntity(
        Company,
        { user_id: this.company.user_id },
        this.company.company_id
      )
    },

    handleEmailaddressInput(emailaddresses) {
      this.company.emailaddresses = emailaddresses.map(item => {
        if (!item.emailaddress) {
          return { emailaddress: item }
        }
        return item
      })
    },

    async handleContactpersonSelected() {
      this.$refs.contactpersons.isMenuActive = false
    },

    async setNewContactperson(contactperson) {
      try {
        this.loading = true
        contactperson.pivot = await CompanyContactperson.createOne({
          company_id: this.company.company_id,
          contactperson_id: contactperson.contactperson_id,
        })
        this.company.contactpersons.push(contactperson)
        this.contactpersonDialog.contactperson = contactperson
        this.contactpersonDialog.contactperson.companies.push(this.company)
      } catch (e) {
        this.handleError([e.message, 'Kunne ikke legge til kontaktperson'])
      } finally {
        this.loading = false
      }
    },

    async createEntity() {
      this.clearErrorAlert()
      await this.createOrUpdateEntity(Company, {
        name: this.company.name,
        orgnr: this.company.orgnr,
        billing_address: this.company.billing_address,
        user_id: this.company.user_id,
      })
    },

    handleError(message) {
      this.showErrorAlert(message)
      this.loading = false
    },
    closeDialog() {
      this.clearErrorAlert()
      this.$emit('closeAndReset')
    },
  },
}
</script>
