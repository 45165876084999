import BaseModel from './BaseModel'

export default class companyIndustry extends BaseModel {
  static entity = 'companies_industries'

  static primaryKey = ['company_id', 'industry_id']

  static fields() {
    return {
      company_id: this.attr(null),
      industry_id: this.attr(null),
    }
  }

  static methodConf = {
    methods: {
      $delete: {
        name: 'delete',
        http: {
          url: '/:company_id/:industry_id/',
          method: 'delete',
        },
      },
    },
  }
}
