import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VuexORM from '@vuex-orm/core'
import VuexORMAxios from '@vuex-orm/plugin-axios'
import database from './database'
import authService from '../auth/authentication.js'

Vue.use(Vuex)

axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.interceptors.response.use(
  response => response,
  error => {
    handleError(error)
  }
)

export const setupStore = () => {
  return authService
    .getAccessToken()
    .then(token => {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token

      VuexORM.use(VuexORMAxios, { axios })

      const store = new Vuex.Store({
        plugins: [VuexORM.install(database)],
      })

      return store
    })
    .catch(e => {
      console.log('Error getting access token', e)
    })
}

function handleError(error) {
  if (error.response.data && error.response.data.message) {
    throw Error(error.response.data.message)
  }
  throw Error(`Det oppsto en feil: ${error.response.status} ${error.response.statusText}`)
}
