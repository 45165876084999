import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.entity)?_c(VDialog,{attrs:{"value":"show","persistent":"","max-width":_vm.maxWidth},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closeDialog.apply(null, arguments)}}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline primary white--text"},[_vm._v(" "+_vm._s(_vm.entity.$id ? _vm.editTitle : _vm.createTitle)+" "),_c(VSpacer),(!_vm.loading)?_c(VBtn,{attrs:{"icon":"","color":"white"},on:{"click":_vm.closeDialog}},[_c(VIcon,[_vm._v("close")])],1):_c(VProgressCircular,{attrs:{"indeterminate":"","color":"white","size":"36"}})],1),_c(VCardText,[_vm._t("default"),_vm._t("form"),_c('ErrorAlert',_vm._b({},'ErrorAlert',_vm.errorAlert,false))],2)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }