import Vue from 'vue'
import vuetify from '@/plugins/vuetify'
import App from './App.vue'
import router from './router'
import moment from 'moment'
import { setupStore } from './store'
import AuthPlugin from './plugins/auth'
import toast from '@/mixins/toast'

Vue.use(AuthPlugin)

Vue.mixin(toast)

Vue.prototype.$moment = moment
moment.locale('nb')

Vue.prototype.$openLinkInNewWindow = url => {
  window.open(url, '_blank')
}

Vue.filter('moment', (val, format) => {
  if (val) {
    return moment(val).format(format)
  }
})
Vue.filter('currency', val => {
  if (val) {
    return Number(val)
      .toFixed(2)
      .toString()
      .replace('.', ',')
      .replace(/\B(?=(\d{3})+(?!\d))/g, '\xa0') //nbsp
  }
})
Vue.filter('orgnr', val => {
  if (val) {
    return val
      .toString()
      .replace(/(.{3})/g, '$1 ')
      .trim()
  }
})
Vue.filter('phone', val => {
  if (val) {
    return val
      .toString()
      .replace(/(.{2})/g, '$1 ')
      .trim()
  }
})
Vue.filter('strLeft', (val, numLetters) => {
  if (val && numLetters) {
    return val.substr(0, numLetters)
  }
})

Vue.config.productionTip = false

setupStore()
  .then(store => {
    new Vue({
      vuetify,
      router,
      store,
      render: h => h(App),
    }).$mount('#app')
  })
  .catch(e => {
    console.log('Error setting up store: ', e)
  })
