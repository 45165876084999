<template>
  <v-layout>
    <v-flex xs12 sm2 offset-sm5>
      <v-card v-if="$currentUser">
        <v-card-title primary-title>
          <div class="headline">Din brukerprofil</div>
        </v-card-title>
        <v-card-text>
          <ul>
            <li>Navn: {{ $currentUser.first_name }} {{ $currentUser.last_name }}</li>
            <li>
              E-post:
              <a :href="`mailto:${$currentUser.email}`">{{ $currentUser.email }}</a>
            </li>
            <li>Telefon: {{ $currentUser.phone }}</li>
          </ul>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import currentUser from '@/mixins/currentUser'

export default {
  mixins: [currentUser],
}
</script>

<style scoped>
ul {
  padding-left: 0;
}

li {
  list-style: none;
}
</style>
