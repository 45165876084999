import BaseModel from './BaseModel'
import Company from './Company'
import CompanyIndustry from './CompanyIndustry.js'

export default class Industry extends BaseModel {
  static entity = 'industries'
  static dataField = 'name'
  static primaryKey = 'industry_id'

  static fields() {
    return {
      industry_id: this.attr(null),
      name: this.string(),
      companies: this.belongsToMany(Company, CompanyIndustry, 'industry_id', 'company_id'),
    }
  }
}
